import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { Editor } from "@tinymce/tinymce-react";
import ImageDropzone from "../Forms/Fields/ImageDropzone";
import "../i18n";
import { Tab, Nav } from "react-bootstrap";
import FormServices from "../Services/FormServices";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import { BASE_URL } from "../Components/Common/Url";

const statusOptions = [
  { value: "publish", label: "Publish" },
  { value: "draft", label: "Draft" },
  { value: "pending", label: "Pending" },
];

const getValidationSchema = (key) => {
  return Yup.object().shape({
    titleEn:
      key === "english"
        ? Yup.string().required("Title is required")
        : Yup.string(),
    titleAr:
      key === "arabic"
        ? Yup.string().required("Title is required")
        : Yup.string(),
    descriptionEn:
      key === "english"
        ? Yup.string().required("Description is required")
        : Yup.string(),
    descriptionAr:
      key === "arabic"
        ? Yup.string().required("Description is required")
        : Yup.string(),
    status: Yup.string().required("Status is required"),
    imageEn:
      key === "english"
        ? Yup.mixed().required("An image is required")
        : Yup.mixed(),
    imageAr:
      key === "arabic"
        ? Yup.mixed().required("An image is required")
        : Yup.mixed(),
  });
};

const EditSpecialityForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [key, setKey] = useState("english");
  const [initialValues, setInitialValues] = useState({
    titleEn: "",
    titleAr: "",
    descriptionEn: "",
    descriptionAr: "",
    status: "",
    imageEn: "",
    imageAr: "",
    slug: "",
    seoTitleEn: "",
    seoKeywordsEn: "",
    seoDescEn: "",
    seoTitleAr: "",
    seoKeywordsAr: "",
    seoDescAr: "",
  });

  useEffect(() => {
    toast.dismiss();
    const fetchSpecialty = async () => {
      try {
        const data = await FormServices.getSpecialtyById(id);
        setInitialValues({
          titleEn: data.titleEn || "",
          titleAr: data.titleAr || "",
          descriptionEn: data.descriptionEn || "",
          descriptionAr: data.descriptionAr || "",
          status: data.status || "",
          imageEn: data.imageEn ? BASE_URL + data.imageEn : "",
          imageAr: data.imageAr ? BASE_URL + data.imageAr : "",
          slug: data.slug,
          seoTitleEn: data.seoTitleEn || "",
          seoKeywordsEn: data.seoKeywordsEn || "",
          seoDescEn: data.seoDescEn || "",
          seoTitleAr: data.seoTitleAr || "",
          seoKeywordsAr: data.seoKeywordsAr || "",
          seoDescAr: data.seoDescAr || "",
        });
      } catch (error) {
        toast.error("Error fetching specialty data");
      }
    };
    fetchSpecialty();
  }, [id]);

  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="row">
        <div className="col">
          <div className="card mb-3">
            <h5 className="card-header">Edit Speciality</h5>
            <div className="card-header pt-2">
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={() => getValidationSchema(key)}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  try {
                    await FormServices.updateSpecialty(id, values);
                    toast.success("Specialty updated successfully!");
                    navigate("/admin/specialities-list");
                  } catch (error) {
                    console.error("Error updating specialty:", error);
                    toast.error("Error updating specialty");
                  } finally {
                    setSubmitting(false);
                  }
                }}
              >
                {({ setFieldValue, isSubmitting }) => (
                  <Form className="container mt-4">
                    <Tab.Container activeKey={key} onSelect={(k) => setKey(k)}>
                      <Nav variant="tabs">
                        <Nav.Item>
                          <Nav.Link eventKey="english">English</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="arabic">Arabic</Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Tab.Content>
                        <Tab.Pane eventKey="english">
                          <div className="mb-3">
                            <label htmlFor="titleEn" className="form-label">
                              Title (English)
                            </label>
                            <Field
                              name="titleEn"
                              type="text"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="titleEn"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                          <div className="mb-3">
                            <label htmlFor="slug" className="form-label">
                              slug (English)
                            </label>
                            <Field
                              name="slug"
                              type="text"
                              className="form-control"
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">
                              Description (English)
                            </label>
                            <Editor
                              apiKey="8v2iaduxz30oj5ayfo3tw8xr5477h08594avuno5p2df6muf"
                              initialValue={initialValues.descriptionEn}
                              onEditorChange={(content) =>
                                setFieldValue("descriptionEn", content)
                              }
                              init={{
                                height: 400,
                                menubar: false,
                                directionality: "ltr",
                                language: "en",
                                plugins: [
                                  "advlist autolink lists link image charmap print preview anchor",
                                  "searchreplace visualblocks code fullscreen",
                                  "insertdatetime media table paste code help wordcount",
                                ],
                                toolbar:
                                  "undo redo | formatselect | bold italic | " +
                                  "link bullist numlist | indent outdent | " +
                                  "image media table blockquote | selectall",
                                content_style: `
                                  body { 
                                    font-family: 'Arial', sans-serif; 
                                    font-size: 14px; 
                                  }`,
                                branding: false,
                              }}
                            />
                            <ErrorMessage
                              name="descriptionEn"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                          <div className="card mb-3">
                            <h5 className="card-header">SEO</h5>

                            <div class="card-body">
                              <div className="mb-3">
                                <label className="form-label">Keywords</label>
                                <Field
                                  className="form-control"
                                  name="seoKeywordsEn"
                                />
                              </div>
                              <div className="mb-3">
                                <label className="form-label">
                                  Description
                                </label>
                                <Field
                                  className="form-control"
                                  name="seoDescEn"
                                />
                              </div>
                              <div className="mb-3">
                                <label className="form-label">Title</label>
                                <Field
                                  className="form-control"
                                  name="seoTitleEn"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="mb-3">
                            <label className="form-label">
                              Image (English)
                            </label>
                            <ImageDropzone
                              onFilesAdded={(acceptedFiles) => {
                                if (acceptedFiles.length > 0) {
                                  setFieldValue("imageEn", acceptedFiles[0]);
                                }
                              }}
                            />
                            {initialValues.imageEn && (
                              <div className="m-2 d-flex flex-column">
                                <img
                                  src={initialValues.imageEn}
                                  alt="Uploaded"
                                  style={{ maxWidth: "100px", height: "auto" }}
                                />
                              </div>
                            )}
                            <ErrorMessage
                              name="imageEn"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="arabic">
                          <div className="mb-3">
                            <label htmlFor="titleAr" className="form-label">
                              Title (Arabic)
                            </label>
                            <Field
                              dir="rtl"
                              name="titleAr"
                              type="text"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="titleAr"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                          <div className="mb-3">
                            <label htmlFor="slug" className="form-label">
                              slug (Arabic)
                            </label>
                            <Field
                              name="slug"
                              type="text"
                              className="form-control"
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">
                              Description (Arabic)
                            </label>
                            <Editor
                              apiKey="8v2iaduxz30oj5ayfo3tw8xr5477h08594avuno5p2df6muf"
                              initialValue={initialValues.descriptionAr}
                              onEditorChange={(content) =>
                                setFieldValue("descriptionAr", content)
                              }
                              init={{
                                height: 400,
                                directionality: "rtl", 
                                language: "ar",
                                menubar: false,
                                plugins: [
                                  "advlist autolink lists link image charmap print preview anchor",
                                  "searchreplace visualblocks code fullscreen",
                                  "insertdatetime media table paste code help wordcount",
                                ],
                                toolbar:
                                  "undo redo | formatselect | bold italic | " +
                                  "link bullist numlist | indent outdent | " +
                                  "image media table blockquote | selectall",
                                content_style: `
      body { 
        font-family: 'Arial', sans-serif; 
        font-size: 14px; 
        direction: rtl; 
        text-align: right; 
      }`,
                              }}
                            />
                            <ErrorMessage
                              name="descriptionAr"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                          <div className="card mb-3">
                            <h5 className="card-header">SEO</h5>
                            <div class="card-body">
                              <div className="mb-3">
                                <label className="form-label">Keywords</label>
                                <Field
                                  className="form-control"
                                  name="seoKeywordsAr"
                                  dir="rtl"
                                />
                              </div>
                              <div className="mb-3">
                                <label className="form-label">
                                  Description
                                </label>
                                <Field
                                  className="form-control"
                                  name="seoDescAr"
                                  dir="rtl"
                                />
                              </div>
                              <div className="mb-3">
                                <label className="form-label">Title</label>
                                <Field
                                  className="form-control"
                                  name="seoTitleAr"
                                  dir="rtl"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Image (Arabic)</label>
                            <ImageDropzone
                              onFilesAdded={(acceptedFiles) => {
                                if (acceptedFiles.length > 0) {
                                  setFieldValue("imageAr", acceptedFiles[0]);
                                }
                              }}
                            />
                            {initialValues.imageAr && (
                              <div className="m-2 d-flex flex-column">
                                <img
                                  src={initialValues.imageAr}
                                  alt="Uploaded"
                                  style={{ maxWidth: "100px", height: "auto" }}
                                />
                              </div>
                            )}

                            <ErrorMessage
                              name="imageAr"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </Tab.Pane>
                        <div className="mb-3">
                          <label className="form-label">Status</label>
                          <Select
                            options={statusOptions}
                            onChange={(option) =>
                              setFieldValue("status", option.value)
                            }
                            value={statusOptions.find(
                              (option) => option.value === initialValues.status
                            )}
                          />
                          <ErrorMessage
                            name="status"
                            component="div"
                            className="text-danger"
                          />
                        </div>

                        <div>
                          <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={isSubmitting}
                          >
                            Submit
                          </button>
                        </div>
                      </Tab.Content>
                    </Tab.Container>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditSpecialityForm;
