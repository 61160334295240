import React, { useEffect, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { url } from "../Common/Url";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";

export default function Vission() {
  const [textEditor, setTextEditor] = useState("");
  const [textmEditor, setTextMEditor] = useState("");
  const [hidden, setHidden] = useState(true);
  const [lan, setLan] = useState("en");
  const [data, setData] = useState({});
  const { token } = useSelector((state) => state.user);

  const [seoTitleEn, setSeoTitleEn] = useState("");
  const [seoKeywordsEn, setSeoKeywordsEn] = useState("");
  const [seoDescEn, setSeoDescEn] = useState("");
  const [seoTitleAr, setSeoTitleAr] = useState("");
  const [seoKeywordsAr, setSeoKeywordsAr] = useState("");
  const [seoDescAr, setSeoDescAr] = useState("");

  const get_aster_clinic = async () => {
    const response = await fetch(`${url}/api/v1/user/vission`, {
      method: "GET",
      headers: {
        token: `${token}`,
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();
    console.log(result);
    setData(result.data);
  };

  useEffect(() => {
    get_aster_clinic();
  }, []);

  const handleLanguge = (e) => {
    if (e.target.value == "Ar") {
      setLan("Ar");
      setTextEditor(data.ar_vision);
      setTextMEditor(data.ar_mission);
      setHidden(false);
      setSeoKeywordsAr(data.seoKeywordsAr);
      setSeoDescAr(data.seoDescAr);
      setSeoTitleAr(data.seoTitleAr);
    } else {
      setLan("en");
      setTextMEditor(data.en_mission);
      setTextEditor(data.en_vision);
      setHidden(false);
      setSeoKeywordsEn(data.seoKeywordsEn);
      setSeoDescEn(data.seoDescEn);
      setSeoTitleEn(data.seoTitleEn);
    }
  };

  const handleVission = async () => {
    console.log(textEditor);
    const response = await fetch(`${url}/api/v1/user/vission/update`, {
      method: "PUT",
      headers: {
        token: `${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        lan,
        textEditor,
        textmEditor,
        seoTitleEn,
        seoKeywordsEn,
        seoDescEn,
        seoTitleAr,
        seoKeywordsAr,
        seoDescAr,
      }),
    });
    const result = await response.json();
    toast(result.message);
    if (result.status == true) {
      setInterval(() => {
        window.location.reload();
      }, 3000);
    }
  };

  return (
    <div class="row g-3">
      <div class="col-md-12">
        <h4>Vission Mission</h4>
        <label for="select2Basic" class="form-label">
          <b>Language</b>
        </label>
        <select
          id="select2Basic"
          class="select2 form-select"
          data-allow-clear="true"
          onChange={(e) => handleLanguge(e)}
        >
          <option value="">Select</option>
          <option value="En">En</option>
          <option value="Ar">Ar</option>
        </select>
      </div>
      {hidden == false && (
        <>
          <div class="col-sm-12">
            <label class="form-label" for="username">
              <b>Vision</b>
            </label>
            <Editor
              key={`editor1-${lan}`}
              apiKey="8v2iaduxz30oj5ayfo3tw8xr5477h08594avuno5p2df6muf" // Your TinyMCE API Key
              value={textEditor}
              onEditorChange={(content) => setTextEditor(content)}
              init={{
                height: 400,
                language: lan === "Ar" ? "ar" : "en", // Set UI language dynamically
                directionality: lan === "Ar" ? "rtl" : "ltr", // Text direction
                menubar: false,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                ],
                toolbar:
                  "heading | bold italic | link bullist numlist | " +
                  "indent outdent | image media table blockquote | " +
                  "undo redo | selectAll",
                content_style: `body { font-family: 'Arial', sans-serif; font-size: 14px; direction: ${
                  lan === "Ar" ? "rtl" : "ltr"
                }; }`,
              }}
            />
            <label class="form-label" for="username">
              <b>Mission</b>
            </label>
            <Editor
              key={`editor2-${lan}`}
              apiKey="8v2iaduxz30oj5ayfo3tw8xr5477h08594avuno5p2df6muf" // Your TinyMCE API Key
              value={textmEditor}
              onEditorChange={(content) => setTextMEditor(content)}
              init={{
                height: 400,
                language: lan === "Ar" ? "ar" : "en", // Set UI language dynamically
                directionality: lan === "Ar" ? "rtl" : "ltr", // Text direction
                menubar: false,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                ],
                toolbar:
                  "heading | bold italic | link bullist numlist | " +
                  "indent outdent | image media table blockquote | " +
                  "undo redo | selectAll",
                content_style: `body { font-family: 'Arial', sans-serif; font-size: 14px; direction: ${
                  lan === "Ar" ? "rtl" : "ltr"
                }; }`,
              }}
            />
            {lan === "en" ? (
              <>
                <div className="card mb-3 mt-3">
                  <h5 className="card-header">SEO (En)</h5>

                  <div class="card-body">
                    <div className="mb-3">
                      <label className="form-label">Keywords</label>
                      <input
                        value={seoKeywordsEn}
                        onChange={(ev) => setSeoKeywordsEn(ev.target.value)}
                        className="form-control"
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Description</label>
                      <input
                        value={seoDescEn}
                        onChange={(ev) => setSeoDescEn(ev.target.value)}
                        className="form-control"
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Title</label>
                      <input
                        value={seoTitleEn}
                        onChange={(ev) => setSeoTitleEn(ev.target.value)}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="card mb-3">
                  <h5 className="card-header">SEO (Ar)</h5>

                  <div class="card-body">
                    <div className="mb-3">
                      <label className="form-label">Keywords</label>
                      <input
                        value={seoKeywordsAr}
                        onChange={(ev) => setSeoKeywordsAr(ev.target.value)}
                        className="form-control"
                        dir="rtl"
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Description</label>
                      <input
                        value={seoDescAr}
                        onChange={(ev) => setSeoDescAr(ev.target.value)}
                        className="form-control"
                        dir="rtl"
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Title</label>
                      <input
                        value={seoTitleAr}
                        onChange={(ev) => setSeoTitleAr(ev.target.value)}
                        className="form-control"
                        dir="rtl"
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
            <button
              class="btn btn-success btn-submit mt-4"
              onClick={handleVission}
            >
              Submit
            </button>
            <ToastContainer />
          </div>
        </>
      )}
    </div>
  );
}
