import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Editor } from "@tinymce/tinymce-react";
import "../../i18n";

import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { url } from "../../Components/Common/Url";
import { Link, useParams } from "react-router-dom";

const ProgramForm = () => {
  const { token } = useSelector((state) => state.user);
  const params = useParams();
  const { id } = params;

  const { t, i18n } = useTranslation();
  const [activeLanguage, setActiveLanguage] = useState(i18n.language);
  const [answer, setAnswer] = useState("");
  const [question, setQuestion] = useState("");
  const [seoTitleEn, setSeoTitleEn] = useState("");
  const [seoKeywordsEn, setSeoKeywordsEn] = useState("");
  const [seoDescEn, setSeoDescEn] = useState("");
  const [seoTitleAr, setSeoTitleAr] = useState("");
  const [seoKeywordsAr, setSeoKeywordsAr] = useState("");
  const [seoDescAr, setSeoDescAr] = useState("");
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setActiveLanguage(lng);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!question && !answer) {
      return toast(`Please Enter Question and Answer`);
    }
    const response = await fetch(`${url}/api/v1/programs/add`, {
      method: "POST",
      headers: {
        token: `${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        lan: activeLanguage,
        answer,
        question,
        seoTitleEn,
        seoKeywordsEn,
        seoDescEn,
        seoTitleAr,
        seoKeywordsAr,
        seoDescAr,
      }),
    });
    const result = await response.json();

    toast(result.message);
    if (result.status == true) {
      setInterval(() => {
        window.location.reload();
      }, 3000);
    }
  };

  const get_signle_faq = async () => {
    const response = await fetch(`${url}/api/v1/programs/get/${id}`, {
      method: "GET",
      headers: {
        token: `${token}`,
      },
    });
    const result = await response.json();
    console.log("single data", result);
    if (activeLanguage == "ar") {
      setQuestion(result.data.ar_question);
      setAnswer(result.data.ar_answer);
      setSeoKeywordsAr(result.data.seoKeywordsAr);
      setSeoDescAr(result.data.seoDescAr);
      setSeoTitleAr(result.data.seoTitleAr);
    } else {
      setQuestion(result.data.en_question);
      setAnswer(result.data.en_answer);
      setSeoKeywordsEn(result.data.seoKeywordsEn);
      setSeoDescEn(result.data.seoDescEn);
      setSeoTitleEn(result.data.seoTitleEn);
    }
  };

  useEffect(() => {
    if (id) {
      get_signle_faq();
    } else {
      setQuestion("");
      setAnswer("");
    }
  }, [id, activeLanguage]);

  const handleupdate = async (e) => {
    e.preventDefault();
    console.log(answer);
    console.log(question);
    try {
      const response = await fetch(`${url}/api/v1/programs/update/${id}`, {
        method: "PUT",
        headers: {
          token: `${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          lan: activeLanguage,
          answer,
          question,
          seoTitleEn,
          seoKeywordsEn,
          seoDescEn,
          seoTitleAr,
          seoKeywordsAr,
          seoDescAr,
        }),
      });

      const result = await response.json();
      console.log(result);
      toast.success(result.message);
      if (result.status == true) {
        setInterval(() => {
          window.location.reload();
        }, 3000);
      }
    } catch (error) {
      console.log("error", error);
      toast.error(error);
    }
  };

  return (
    <div className="col-md-4">
      <ToastContainer />
      <div className="card mb-3">
        <div className="card-header pt-2">
          <ul className="nav nav-tabs card-header-tabs" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${
                  activeLanguage === "en" ? "active" : ""
                }`}
                onClick={() => changeLanguage("en")}
              >
                English
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${
                  activeLanguage === "ar" ? "active" : ""
                }`}
                onClick={() => changeLanguage("ar")}
              >
                Arabic
              </button>
            </li>
          </ul>
        </div>

        <div className="tab-content">
          <div className="tab-pane fade active show" role="tabpanel">
            <form onSubmit={id ? handleupdate : handleSubmit}>
              <div className="row g-3">
                <div className="col-lg-12">
                  <label className="form-label">{t("Question")}</label>
                  <input
                    dir={activeLanguage == "ar" ? "rtl" : "ltr"}
                    type="text"
                    className="form-control"
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                  />
                </div>
                <div className="col-md-12">
                  <label className="form-label">{t("Answer")}</label>
                  <Editor
                    apiKey="8v2iaduxz30oj5ayfo3tw8xr5477h08594avuno5p2df6muf"
                    key={`editor1-${activeLanguage}`}
                    value={answer}
                    init={{
                      language: activeLanguage === "ar" ? "ar" : "en", // UI and content language
                      directionality: activeLanguage === "ar" ? "rtl" : "ltr", // Content direction
                      menubar: false,
                      toolbar: [
                        "heading",
                        "|",
                        "bold",
                        "italic",
                        "|",
                        "link",
                        "bullist",
                        "numlist",
                        "|",
                        "indent",
                        "outdent",
                        "|",
                        "image",
                        "blockquote",
                        "table",
                        "media",
                        "|",
                        "undo",
                        "redo",
                        "|",
                        "selectall",
                      ],
                      plugins: [
                        "advlist autolink link image lists charmap print preview hr anchor pagebreak",
                        "searchreplace wordcount visualblocks visualchars insertdatetime nonbreaking",
                        "table contextmenu directionality emoticons template paste textcolor colorpicker textpattern",
                      ],
                      content_style:
                        activeLanguage === "ar"
                          ? "body { text-align: right; }"
                          : "body { text-align: left; }",
                      setup: (editor) => {
                        editor.on("change", () => {
                          const data = editor.getContent();
                          setAnswer(data); // Update the state with the new content
                        });
                      },
                    }}
                  />
                </div>
              </div>
              {activeLanguage === "en" ? (
                <>
                  <div className="card mb-3 mt-3">
                    <h5 className="card-header">Corporate Wellness SEO (En)</h5>

                    <div class="card-body">
                      <div className="mb-3">
                        <label className="form-label">Keywords</label>
                        <input
                          value={seoKeywordsEn}
                          onChange={(ev) => setSeoKeywordsEn(ev.target.value)}
                          className="form-control"
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Description</label>
                        <input
                          value={seoDescEn}
                          onChange={(ev) => setSeoDescEn(ev.target.value)}
                          className="form-control"
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Title</label>
                        <input
                          value={seoTitleEn}
                          onChange={(ev) => setSeoTitleEn(ev.target.value)}
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="card mb-3">
                    <h5 className="card-header">Corporate Wellness SEO (Ar)</h5>

                    <div class="card-body">
                      <div className="mb-3">
                        <label className="form-label">Keywords</label>
                        <input
                          value={seoKeywordsAr}
                          onChange={(ev) => setSeoKeywordsAr(ev.target.value)}
                          className="form-control"
                          dir="rtl"
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Description</label>
                        <input
                          value={seoDescAr}
                          onChange={(ev) => setSeoDescAr(ev.target.value)}
                          className="form-control"
                          dir="rtl"
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Title</label>
                        <input
                          value={seoTitleAr}
                          onChange={(ev) => setSeoTitleAr(ev.target.value)}
                          className="form-control"
                          dir="rtl"
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div class="pt-4">
                <button
                  type="submit"
                  class="btn btn-primary me-sm-3 me-1 waves-effect waves-light"
                >
                  {t("Submit")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgramForm;
