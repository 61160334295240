import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../../i18n";
import { useSelector } from "react-redux";
import axios from "axios";
import { url, Image } from "../../Components/Common/Url";
import { ToastContainer, toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import PdfUpload from "./../PdfUpload";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";

const PackageForm = () => {
  const params = useParams();
  const { id } = params;

  const { token } = useSelector((state) => state.user);
  const { t, i18n } = useTranslation();
  const [activeLanguage, setActiveLanguage] = useState(i18n.language);
  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");
  const [sdescription, setSdescription] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [seoTitleEn, setSeoTitleEn] = useState("");
  const [seoKeywordsEn, setSeoKeywordsEn] = useState("");
  const [seoDescEn, setSeoDescEn] = useState("");
  const [seoTitleAr, setSeoTitleAr] = useState("");
  const [seoKeywordsAr, setSeoKeywordsAr] = useState("");
  const [seoDescAr, setSeoDescAr] = useState("");
  const navigate = useNavigate();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setActiveLanguage(lng);
  };

  const handleStep = async () => {
    if (!title) {
      return toast.error("Please Enter Title");
    }

    //alert(cat)
    const formData = new FormData();
    formData.append("lan", activeLanguage);
    formData.append("title", title);

    formData.append("description", description);
    formData.append("sdescription", sdescription);
    formData.append("seoTitleEn", seoTitleEn);
    formData.append("seoKeywordsEn", seoKeywordsEn);
    formData.append("seoDescEn", seoDescEn);
    formData.append("seoTitleAr", seoTitleAr);
    formData.append("seoKeywordsAr", seoKeywordsAr);
    formData.append("seoDescAr", seoDescAr);

    try {
      const { data } = await axios.post(`${url}/api/v1/package/add`, formData, {
        headers: {
          "Content-Type": "application/json",
          token: `${token}`,
        },
      });

      toast.success(data.message);
      if (data.status == true) {
        window.location.reload();
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (id) {
      const get_step_data = async () => {
        const { data } = await axios.get(`${url}/api/v1/package/get/${id}`, {
          headers: {
            token: `${token}`,
          },
        });
        console.log(data);
        if (activeLanguage === "ar") {
          setDescription(data.data.ar_description);
          setTitle(data.data.ar_title);
          // setSdescription(data.data.ar_short_description);
          setSeoKeywordsAr(data.data.seoKeywordsAr);
          setSeoDescAr(data.data.seoDescAr);
          setSeoTitleAr(data.data.seoTitleAr);
        } else {
          setDescription(data.data.en_description);
          setTitle(data.data.en_title);
          // setSdescription(data.data.en_short_description);
          setSeoKeywordsEn(data.data.seoKeywordsEn);
          setSeoDescEn(data.data.seoDescEn);
          setSeoTitleEn(data.data.seoTitleEn);
        }
      };
      get_step_data();
    } else {
      setDescription("");
      setTitle("");

      setSdescription("");
    }
  }, [id, activeLanguage]);

  const handleStepUpdate = async () => {
    const formData = new FormData();
    formData.append("lan", activeLanguage);
    formData.append("title", title);
    formData.append("description", description);
    formData.append("sdescription", sdescription);
    formData.append("seoTitleEn", seoTitleEn);
    formData.append("seoKeywordsEn", seoKeywordsEn);
    formData.append("seoDescEn", seoDescEn);
    formData.append("seoTitleAr", seoTitleAr);
    formData.append("seoKeywordsAr", seoKeywordsAr);
    formData.append("seoDescAr", seoDescAr);

    try {
      const { data } = await axios.put(
        `${url}/api/v1/package/update/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            token: `${token}`,
          },
        }
      );

      toast.success(data.message);
      if (data.status == true) {
        window.location.reload();
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className="col-md-4">
      <ToastContainer />
      <div className="card mb-3">
        <div className="card-header pt-2">
          <ul className="nav nav-tabs card-header-tabs" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${
                  activeLanguage === "en" ? "active" : ""
                }`}
                onClick={() => changeLanguage("en")}
              >
                English
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${
                  activeLanguage === "ar" ? "active" : ""
                }`}
                onClick={() => changeLanguage("ar")}
              >
                Arabic
              </button>
            </li>
          </ul>
        </div>

        <div className="tab-content">
          <div className="tab-pane fade active show" role="tabpanel">
            <div>
              <div className="row g-3">
                <div className="col-md-12">
                  <label className="form-label">{t("Title")}</label>
                  <input
                    type="text"
                    dir={activeLanguage == "ar" ? "rtl" : "ltr"}
                    className="form-control"
                    name="name"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
                <div className="col-md-12">
                  <label className="form-label">{t("Short Description")}</label>
                  <input
                    type="text"
                    dir={activeLanguage == "ar" ? "rtl" : "ltr"}
                    className="form-control"
                    name="name"
                    value={sdescription}
                    onChange={(e) => setSdescription(e.target.value)}
                  />
                </div>

                <div className="col-md-12">
                  <label className="form-label">{t("Description")}</label>
                  <br />
                  <Editor
                    apiKey="8v2iaduxz30oj5ayfo3tw8xr5477h08594avuno5p2df6muf"
                    key={`editor1-${activeLanguage}`}
                    value={description}
                    init={{
                      language: activeLanguage === "ar" ? "ar" : "en", // UI and content language
                      directionality: activeLanguage === "ar" ? "rtl" : "ltr", // Content direction
                      menubar: false,
                      toolbar: [
                        "heading",
                        "|",
                        "bold",
                        "italic",
                        "|",
                        "link",
                        "bullist",
                        "numlist",
                        "|",
                        "indent",
                        "outdent",
                        "|",
                        "image",
                        "blockquote",
                        "table",
                        "media",
                        "|",
                        "undo",
                        "redo",
                        "|",
                        "selectall",
                      ],
                      plugins: [
                        "advlist autolink link image lists charmap print preview hr anchor pagebreak",
                        "searchreplace wordcount visualblocks visualchars insertdatetime nonbreaking",
                        "table contextmenu directionality emoticons template paste textcolor colorpicker textpattern",
                      ],
                      content_style:
                        activeLanguage === "ar"
                          ? "body { text-align: right; }"
                          : "body { text-align: left; }",
                      heading_plugin: {
                        options: [
                          {
                            model: "paragraph",
                            title: "Paragraph",
                            class: "mce-paragraph",
                          },
                          {
                            model: "heading1",
                            view: "h1",
                            title: "Heading 1",
                            class: "mce-heading1",
                          },
                          {
                            model: "heading2",
                            view: "h2",
                            title: "Heading 2",
                            class: "mce-heading2",
                          },
                          {
                            model: "heading3",
                            view: "h3",
                            title: "Heading 3",
                            class: "mce-heading3",
                          },
                          {
                            model: "heading4",
                            view: "h4",
                            title: "Heading 4",
                            class: "mce-heading4",
                          },
                          {
                            model: "heading5",
                            view: "h5",
                            title: "Heading 5",
                            class: "mce-heading5",
                          },
                          {
                            model: "heading6",
                            view: "h6",
                            title: "Heading 6",
                            class: "mce-heading6",
                          },
                        ],
                      },
                      setup: (editor) => {
                        editor.on("change", () => {
                          const data = editor.getContent();
                          setDescription(data); // Update the state with the new content
                        });
                      },
                    }}
                  />
                  {activeLanguage === "en" ? (
                    <>
                      <div className="card mb-3 mt-3">
                        <h5 className="card-header">
                          Corporate Wellness SEO (En)
                        </h5>

                        <div class="card-body">
                          <div className="mb-3">
                            <label className="form-label">Keywords</label>
                            <input
                              value={seoKeywordsEn}
                              onChange={(ev) =>
                                setSeoKeywordsEn(ev.target.value)
                              }
                              className="form-control"
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Description</label>
                            <input
                              value={seoDescEn}
                              onChange={(ev) => setSeoDescEn(ev.target.value)}
                              className="form-control"
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Title</label>
                            <input
                              value={seoTitleEn}
                              onChange={(ev) => setSeoTitleEn(ev.target.value)}
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="card mb-3">
                        <h5 className="card-header">
                          Corporate Wellness SEO (Ar)
                        </h5>

                        <div class="card-body">
                          <div className="mb-3">
                            <label className="form-label">Keywords</label>
                            <input
                              value={seoKeywordsAr}
                              onChange={(ev) =>
                                setSeoKeywordsAr(ev.target.value)
                              }
                              className="form-control"
                              dir="rtl"
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Description</label>
                            <input
                              value={seoDescAr}
                              onChange={(ev) => setSeoDescAr(ev.target.value)}
                              className="form-control"
                              dir="rtl"
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Title</label>
                            <input
                              value={seoTitleAr}
                              onChange={(ev) => setSeoTitleAr(ev.target.value)}
                              className="form-control"
                              dir="rtl"
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div class="pt-4">
                {id ? (
                  <button
                    type="submit"
                    class="btn btn-primary me-sm-3 me-1 waves-effect waves-light"
                    onClick={handleStepUpdate}
                  >
                    {t("Update")}
                  </button>
                ) : (
                  <button
                    type="submit"
                    class="btn btn-primary me-sm-3 me-1 waves-effect waves-light"
                    onClick={handleStep}
                  >
                    {t("Submit")}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const dropzoneStyles = {
  border: "2px dashed #cccccc",
  borderRadius: "4px",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
};

export default PackageForm;
