import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../../i18n";
import { useSelector } from "react-redux";
import axios from "axios";
import { url, Image } from "../../Components/Common/Url";
import { ToastContainer, toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import PdfUpload from "./../PdfUpload";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const SpecialClinicTypeForm = () => {
  const params = useParams();
  const { id } = params;

  const { token } = useSelector((state) => state.user);
  const { t, i18n } = useTranslation();
  const [activeLanguage, setActiveLanguage] = useState(i18n.language);
  const [title, setTitle] = useState("");
  const [image, setImage] = useState(null);
  const [description, setDescription] = useState("");
  const [pdf, setPdf] = useState(null);
  const [error, seError] = useState(null);
  const [singleData, setSingleData] = useState({});
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleDrop = useCallback((acceptedFiles) => {
    // Assuming you only want to handle one file at a time
    const file = acceptedFiles[0];
    if (file.type.startsWith("image/")) {
      // Do something with the valid image file
      console.log("Valid image file:", file);
      setImage(file);
    } else {
      // Handle invalid file type
      toast.error("Invalid file type. Please upload an image file.");
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: ["image/*"],
    onDrop: handleDrop,
  });

  const changeLanguage = (lng) => {
    console.log(singleData);
    i18n.changeLanguage(lng);
    setActiveLanguage(lng);
  };

  const handleExcellance = async () => {
    if (!title) {
      return toast.error("Please Enter Title");
    }
    const formData = new FormData();
    formData.append("lan", activeLanguage);
    formData.append("title", title);
    formData.append("image", image);
    formData.append("pdf", pdf);
    formData.append("description", description);

    try {
      const { data } = await axios.post(
        `${url}/api/v1/special/clinic/type/add`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            token: `${token}`,
          },
        }
      );

      toast.success(data.message);
      if (data.status == true) {
        window.location.reload();
      }
    } catch (error) {
      toast.error("Failed to upload files.");
    }
  };

  useEffect(() => {
    if (id) {
      const get_excellanace_data = async () => {
        const { data } = await axios.get(
          `${url}/api/v1/special/clinic/type/get/${id}`,
          {
            headers: {
              token: `${token}`,
            },
          }
        );
        console.log(activeLanguage);
        if (activeLanguage === "ar") {
          setTitle(data.data.ar_title);
          setImage(data.data.ar_image);
          setPdf(data.data.ar_pdf);
          setDescription(data.data.ar_description);
        } else {
          setTitle(data.data.en_title);
          setImage(data.data.en_image);
          setPdf(data.data.en_pdf);
          setDescription(data.data.en_description);
        }
      };
      get_excellanace_data();
    } else {
      setTitle("");
      setImage("");
      setPdf("");
      setDescription("");
    }
  }, [id, activeLanguage]);

  const handleExcellanceUpdate = async () => {
    const formData = new FormData();
    formData.append("lan", activeLanguage);
    formData.append("title", title);
    formData.append("image", image);
    formData.append("pdf", pdf);
    formData.append("description", description);

    try {
      const { data } = await axios.put(
        `${url}/api/v1/special/clinic/type/update/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            token: `${token}`,
          },
        }
      );

      toast.success(data.message);
      if (data.status == true) {
        window.location.reload();
      }
    } catch (error) {
      toast.error("Failed to upload files.");
    }
  };

  const handleImage = (url) => {
    // alert(url)
    setShow(true);
  };

  return (
    <div className="col-lg-4">
      <ToastContainer />
      <div className="card mb-3">
        <div className="card-header pt-2">
          <ul className="nav nav-tabs card-header-tabs" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${
                  activeLanguage === "en" ? "active" : ""
                }`}
                onClick={() => changeLanguage("en")}
              >
                English
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${
                  activeLanguage === "ar" ? "active" : ""
                }`}
                onClick={() => changeLanguage("ar")}
              >
                Arabic
              </button>
            </li>
          </ul>
        </div>

        <div className="tab-content">
          <div className="tab-pane fade active show" role="tabpanel">
            <div>
              <div className="row g-3">
                <div className="col-md-12">
                  <label className="form-label">{t("Title")}</label>
                  <input
                    type="text"
                    dir={activeLanguage == "ar" ? "rtl" : "ltr"}
                    className="form-control"
                    name="name"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
                <div className="col-md-12">
                  <label className="form-label">{t("Description")}</label>
                  <Editor
                    key={`editor1-${activeLanguage}`}
                    apiKey="8v2iaduxz30oj5ayfo3tw8xr5477h08594avuno5p2df6muf" // Your TinyMCE API key
                    value={description} // Current content value
                    init={{
                      language: activeLanguage === "ar" ? "ar" : "en", // Set language based on activeLanguage
                      direction: activeLanguage === "ar" ? "rtl" : "ltr", // Set content direction
                      plugins: [
                        "link",
                        "image",
                        "lists",
                        "table",
                        "media",
                        "blockquote",
                        "undo",
                        "redo",
                      ],
                      toolbar: [
                        "heading",
                        "|",
                        "bold",
                        "italic",
                        "|",
                        "link",
                        "bullist",
                        "numlist",
                        "|",
                        "indent",
                        "outdent",
                        "|",
                        "image",
                        "blockquote",
                        "table",
                        "media",
                        "|",
                        "undo",
                        "redo",
                        "|",
                        "selectall",
                      ],
                      style_formats: [
                        { title: "Paragraph", format: "p" },
                        { title: "Heading 1", format: "h1" },
                        { title: "Heading 2", format: "h2" },
                        { title: "Heading 3", format: "h3" },
                        { title: "Heading 4", format: "h4" },
                        { title: "Heading 5", format: "h5" },
                        { title: "Heading 6", format: "h6" },
                      ],
                      content_style:
                        "body { font-family: Arial, sans-serif; font-size: 14px; }", // Optional style
                    }}
                    onEditorChange={(content, editor) => {
                      setDescription(content); // Update the state with new content
                    }}
                  />
                </div>
                <div className="col-md-12">
                  <label className="form-label">{t("Image")}</label>
                  <br />

                  <div {...getRootProps()} style={dropzoneStyles}>
                    <input {...getInputProps()} />
                    <p>
                      Drag 'n' drop an image file here, or click to select one
                    </p>
                  </div>
                  {image && image.path}

                  {image && (
                    <img
                      src={`${Image}/${image}`}
                      width={"100px"}
                      className="mt-4"
                      onClick={() => handleImage(`${Image}/${image}`)}
                      style={{ cursor: "pointer" }}
                    />
                  )}
                </div>

                <div className="col-md-12">
                  <label className="form-label">{t("PDF")}</label>
                  {pdf && (
                    <Link to={`${Image}/${pdf}`} target="_blink">
                      View
                    </Link>
                  )}

                  <PdfUpload pdf={pdf} setPdf={setPdf} setError={seError} />
                  {pdf && pdf.path}
                  {error && <span style={{ color: "red" }}>{error}</span>}
                </div>
              </div>
              <div class="pt-4">
                {id ? (
                  <button
                    type="submit"
                    class="btn btn-primary me-sm-3 me-1 waves-effect waves-light"
                    onClick={handleExcellanceUpdate}
                  >
                    {t("Update")}
                  </button>
                ) : (
                  <button
                    type="submit"
                    class="btn btn-primary me-sm-3 me-1 waves-effect waves-light"
                    onClick={handleExcellance}
                  >
                    {t("Submit")}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          {image && <img src={`${Image}/${image}`} width={"500px"} />}
        </Modal.Body>
      </Modal>
    </div>
  );
};

const dropzoneStyles = {
  border: "2px dashed #cccccc",
  borderRadius: "4px",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
};

export default SpecialClinicTypeForm;
