import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../../i18n";
import { useSelector } from "react-redux";
import axios from "axios";
import { url, Image } from "../../Components/Common/Url";
import { ToastContainer, toast } from "react-toastify";
import { useDropzone } from "react-dropzone";

import { Link, useNavigate, useParams } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import FabIcon from "../FabIcon";
import AboutImageUpload from "../AboutusImageUpload";

const EmotionalDescriptionForm = () => {
  const { token } = useSelector((state) => state.user);
  const { t, i18n } = useTranslation();
  const [activeLanguage, setActiveLanguage] = useState(i18n.language);
  const [description, setDescription] = useState("");
  const [ptitle, setPtitle] = useState("");
  const [pdescription, setPdescription] = useState("");
  const [pctitle, setPctitle] = useState("");
  const [pcdescription, setPcdescription] = useState("");
  const [appointment, setAppointment] = useState("");

  const [singleData, setSingleData] = useState({});
  const navigate = useNavigate();

  const changeLanguage = (lng) => {
    console.log(singleData);
    i18n.changeLanguage(lng);
    setActiveLanguage(lng);
  };

  useEffect(() => {
    const get_excellanace_data = async () => {
      const { data } = await axios.get(
        `${url}/api/v1/emotional/description/get/`,
        {
          headers: {
            token: `${token}`,
          },
        }
      );
      console.log(data);
      if (activeLanguage === "ar") {
        setDescription(data.data.ar_description);
        setPtitle(data.data.ar_psychology_title);
        setPdescription(data.data.ar_psychology_description);
        setPctitle(data.data.ar_psychiatry_title);
        setPcdescription(data.data.ar_psychiatry_description);
        setAppointment(data.data.ar_appointment);
      } else {
        setDescription(data.data.en_description);
        setPtitle(data.data.en_psychology_title);
        setPdescription(data.data.en_psychology_description);
        setPctitle(data.data.en_psychiatry_title);
        setPcdescription(data.data.en_psychiatry_description);
        setAppointment(data.data.en_appointment);
      }
    };
    get_excellanace_data();
  }, [activeLanguage]);

  const handleExcellanceUpdate = async () => {
    const formData = new FormData();
    formData.append("lan", activeLanguage);
    formData.append("description", description);

    formData.append("ptitle", ptitle);
    formData.append("pdescription", pdescription);
    formData.append("pctitle", pctitle);
    formData.append("pcdescription", pcdescription);
    formData.append("appointment", appointment);

    try {
      const { data } = await axios.put(
        `${url}/api/v1/emotional/description/update/`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            token: `${token}`,
          },
        }
      );

      toast.success(data.message);
      if (data.status == true) {
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };

  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <ToastContainer />
      <div className="row">
        <div className="col">
          <div className="card mb-3">
            <div className="card-header pt-2">
              <ul className="nav nav-tabs card-header-tabs" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${
                      activeLanguage === "en" ? "active" : ""
                    }`}
                    onClick={() => changeLanguage("en")}
                  >
                    English
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${
                      activeLanguage === "ar" ? "active" : ""
                    }`}
                    onClick={() => changeLanguage("ar")}
                  >
                    Arabic
                  </button>
                </li>
              </ul>
            </div>

            <div className="tab-content">
              <div className="tab-pane fade active show" role="tabpanel">
                <div>
                  <div className="row g-3">
                    <div className="col-md-12">
                      <label className="form-label">{t("Description")}</label>
                      <Editor
                        key={`editor1-${activeLanguage}`}
                        apiKey="8v2iaduxz30oj5ayfo3tw8xr5477h08594avuno5p2df6muf" // Your TinyMCE API key
                        value={description} // Current editor content
                        init={{
                          language: activeLanguage === "ar" ? "ar" : "en", // UI language
                          direction: activeLanguage === "ar" ? "rtl" : "ltr", // Content direction (RTL for Arabic)
                          plugins: [
                            "link",
                            "image",
                            "lists",
                            "table",
                            "media",
                            "blockquote",
                            "undo",
                            "redo",
                          ],
                          toolbar: [
                            "heading",
                            "|",
                            "bold",
                            "italic",
                            "|",
                            "link",
                            "bullist",
                            "numlist",
                            "|",
                            "indent",
                            "outdent",
                            "|",
                            "image",
                            "blockquote",
                            "table",
                            "media",
                            "|",
                            "undo",
                            "redo",
                            "|",
                            "selectall",
                          ],
                          // Customize heading options
                          style_formats: [
                            { title: "Paragraph", format: "p" },
                            { title: "Heading 1", format: "h1" },
                            { title: "Heading 2", format: "h2" },
                            { title: "Heading 3", format: "h3" },
                            { title: "Heading 4", format: "h4" },
                            { title: "Heading 5", format: "h5" },
                            { title: "Heading 6", format: "h6" },
                          ],
                          content_style:
                            "body { font-family: Arial, sans-serif; font-size: 14px; }", // Optional: style for content
                        }}
                        onEditorChange={(content, editor) => {
                          setDescription(content); // Update the description state with the new content
                        }}
                      />
                    </div>

                    <div className="col-md-12">
                      <h4>{t("PSYCHOLOGY")}</h4>
                      <label className="form-label">{t("Title")}</label>
                      <input
                        type="text"
                        dir={activeLanguage == "ar" ? "rtl" : "ltr"}
                        className="form-control"
                        value={ptitle}
                        onChange={(e) => setPtitle(e.target.value)}
                      />
                      <label className="form-label">{t("Description")}</label>
                      <Editor
                        key={`editor2-${activeLanguage}`}
                        apiKey="8v2iaduxz30oj5ayfo3tw8xr5477h08594avuno5p2df6muf" // Your TinyMCE API key
                        value={pdescription} // Current editor content
                        init={{
                          language: activeLanguage === "ar" ? "ar" : "en", // UI language
                          direction: activeLanguage === "ar" ? "rtl" : "ltr", // Content direction (RTL for Arabic)
                          plugins: [
                            "link",
                            "image",
                            "lists",
                            "table",
                            "media",
                            "blockquote",
                            "undo",
                            "redo",
                          ],
                          toolbar: [
                            "heading",
                            "|",
                            "bold",
                            "italic",
                            "|",
                            "link",
                            "bullist",
                            "numlist",
                            "|",
                            "indent",
                            "outdent",
                            "|",
                            "image",
                            "blockquote",
                            "table",
                            "media",
                            "|",
                            "undo",
                            "redo",
                            "|",
                            "selectall",
                          ],
                          style_formats: [
                            { title: "Paragraph", format: "p" },
                            { title: "Heading 1", format: "h1" },
                            { title: "Heading 2", format: "h2" },
                            { title: "Heading 3", format: "h3" },
                            { title: "Heading 4", format: "h4" },
                            { title: "Heading 5", format: "h5" },
                            { title: "Heading 6", format: "h6" },
                          ],
                          content_style:
                            "body { font-family: Arial, sans-serif; font-size: 14px; }", // Optional: style for content
                        }}
                        onEditorChange={(content, editor) => {
                          setPdescription(content); // Update the pdescription state with the new content
                        }}
                      />
                    </div>

                    <div className="col-md-12">
                      <h4>{t("PSYCHIATRY")}</h4>
                      <label className="form-label">{t("Title")}</label>
                      <input
                        type="text"
                        dir={activeLanguage == "ar" ? "rtl" : "ltr"}
                        className="form-control"
                        value={pctitle}
                        onChange={(e) => setPctitle(e.target.value)}
                      />
                      <label className="form-label">{t("Description")}</label>
                      <Editor
                        key={`editor3-${activeLanguage}`}
                        apiKey="8v2iaduxz30oj5ayfo3tw8xr5477h08594avuno5p2df6muf" // Your TinyMCE API key
                        value={pcdescription} // Current editor content
                        init={{
                          language: activeLanguage === "ar" ? "ar" : "en", // UI language
                          direction: activeLanguage === "ar" ? "rtl" : "ltr", // Content direction (RTL for Arabic)
                          plugins: [
                            "link",
                            "image",
                            "lists",
                            "table",
                            "media",
                            "blockquote",
                            "undo",
                            "redo",
                          ],
                          toolbar: [
                            "heading",
                            "|",
                            "bold",
                            "italic",
                            "|",
                            "link",
                            "bullist",
                            "numlist",
                            "|",
                            "indent",
                            "outdent",
                            "|",
                            "image",
                            "blockquote",
                            "table",
                            "media",
                            "|",
                            "undo",
                            "redo",
                            "|",
                            "selectall",
                          ],
                          style_formats: [
                            { title: "Paragraph", format: "p" },
                            { title: "Heading 1", format: "h1" },
                            { title: "Heading 2", format: "h2" },
                            { title: "Heading 3", format: "h3" },
                            { title: "Heading 4", format: "h4" },
                            { title: "Heading 5", format: "h5" },
                            { title: "Heading 6", format: "h6" },
                          ],
                          content_style:
                            "body { font-family: Arial, sans-serif; font-size: 14px; }", // Optional: style for content
                        }}
                        onEditorChange={(content, editor) => {
                          setPcdescription(content); // Update the pcdescription state with the new content
                        }}
                      />
                    </div>

                    <div className="col-md-12">
                      <h4>{t("Appointment")}</h4>

                      <label className="form-label">{t("Appointment")}</label>
                      <Editor
                        key={`editor4-${activeLanguage}`}
                        apiKey="8v2iaduxz30oj5ayfo3tw8xr5477h08594avuno5p2df6muf" // Your TinyMCE API key
                        value={appointment} // Current editor content
                        init={{
                          language: activeLanguage === "ar" ? "ar" : "en", // UI language
                          direction: activeLanguage === "ar" ? "rtl" : "ltr", // Content direction (RTL for Arabic)
                          plugins: [
                            "link",
                            "image",
                            "lists",
                            "table",
                            "media",
                            "blockquote",
                            "undo",
                            "redo",
                          ],
                          toolbar: [
                            "heading",
                            "|",
                            "bold",
                            "italic",
                            "|",
                            "link",
                            "bullist",
                            "numlist",
                            "|",
                            "indent",
                            "outdent",
                            "|",
                            "image",
                            "blockquote",
                            "table",
                            "media",
                            "|",
                            "undo",
                            "redo",
                            "|",
                            "selectall",
                          ],
                          style_formats: [
                            { title: "Paragraph", format: "p" },
                            { title: "Heading 1", format: "h1" },
                            { title: "Heading 2", format: "h2" },
                            { title: "Heading 3", format: "h3" },
                            { title: "Heading 4", format: "h4" },
                            { title: "Heading 5", format: "h5" },
                            { title: "Heading 6", format: "h6" },
                          ],
                          content_style:
                            "body { font-family: Arial, sans-serif; font-size: 14px; }", // Optional: style for content
                        }}
                        onEditorChange={(content, editor) => {
                          setAppointment(content); // Update the appointment state with the new content
                        }}
                      />
                    </div>
                  </div>
                  <div class="pt-4">
                    <button
                      type="submit"
                      class="btn btn-primary me-sm-3 me-1 waves-effect waves-light"
                      onClick={handleExcellanceUpdate}
                    >
                      {t("Update")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const dropzoneStyles = {
  border: "2px dashed #cccccc",
  borderRadius: "4px",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
};

export default EmotionalDescriptionForm;
