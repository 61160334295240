import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import "../../i18n";

import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { url } from "../../Components/Common/Url";
import { Link, useParams } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";

const DoctorSpeakForm = () => {
  const { token } = useSelector((state) => state.user);
  const params = useParams();
  const { id } = params;
  const [seoTitleEn, setSeoTitleEn] = useState("");
  const [seoKeywordsEn, setSeoKeywordsEn] = useState("");
  const [seoDescEn, setSeoDescEn] = useState("");
  const [seoTitleAr, setSeoTitleAr] = useState("");
  const [seoKeywordsAr, setSeoKeywordsAr] = useState("");
  const [seoDescAr, setSeoDescAr] = useState("");

  const { t, i18n } = useTranslation();
  const [activeLanguage, setActiveLanguage] = useState(i18n.language);
  const [urls, setUrl] = useState("");
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setActiveLanguage(lng);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!url) {
      return toast.error(`Please Enter Title`);
    }
    const response = await fetch(`${url}/api/v1/doctorspeak/add`, {
      method: "POST",
      headers: {
        token: `${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        urls,
        seoTitleEn,
        seoKeywordsEn,
        seoDescEn,
        seoTitleAr,
        seoKeywordsAr,
        seoDescAr,
      }),
    });
    const result = await response.json();

    toast(result.message);
    if (result.status == true) {
      setInterval(() => {
        window.location.reload();
      }, 3000);
    }
  };

  const get_signle_office = async () => {
    const response = await fetch(`${url}/api/v1/doctorspeak/get/url/${id}`, {
      method: "GET",
      headers: {
        token: `${token}`,
      },
    });
    const result = await response.json();
    console.log("single data", result);
    setUrl(result.data.url);
    setSeoKeywordsAr(result.data.seoKeywordsAr);
    setSeoDescAr(result.data.seoDescAr);
    setSeoTitleAr(result.data.seoTitleAr);
    setSeoKeywordsEn(result.data.seoKeywordsEn);
    setSeoDescEn(result.data.seoDescEn);
    setSeoTitleEn(result.data.seoTitleEn);
  };

  useEffect(() => {
    if (id) {
      get_signle_office();
    } else {
      setUrl("");
    }
  }, [id]);

  const handleupdate = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${url}/api/v1/doctorspeak/${id}`, {
        method: "PUT",
        headers: {
          token: `${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          urls,
          seoTitleEn,
          seoKeywordsEn,
          seoDescEn,
          seoTitleAr,
          seoKeywordsAr,
          seoDescAr,
        }),
      });

      const result = await response.json();
      console.log(result);
      toast.success(result.message);
      if (result.status == true) {
        setInterval(() => {
          window.location.reload();
        }, 3000);
      }
    } catch (error) {
      console.log("error", error);
      toast.error(error);
    }
  };

  return (
    <div className="col-md-4">
      <ToastContainer />
      <div className="card mb-3">
        <div className="card-header pt-2">
          <ul className="nav nav-tabs card-header-tabs" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${
                  activeLanguage === "en" ? "active" : ""
                }`}
                onClick={() => changeLanguage("en")}
              >
                English
              </button>
            </li>
          </ul>
        </div>

        <div className="tab-content">
          <div className="tab-pane fade active show" role="tabpanel">
            <form onSubmit={id ? handleupdate : handleSubmit}>
              <div className="row g-3">
                <div className="col-lg-12">
                  <label className="form-label">{t("Url")}</label>
                  <input
                    type="text"
                    className="form-control"
                    value={urls}
                    onChange={(e) => setUrl(e.target.value)}
                  />
                </div>
              </div>
              {/* {activeLanguage === "en" ? (
                <> */}
              <div className="card mb-3 mt-3">
                <h5 className="card-header">Asthetics By Aster SEO (En)</h5>

                <div class="card-body">
                  <div className="mb-3">
                    <label className="form-label">Keywords</label>
                    <input
                      value={seoKeywordsEn}
                      onChange={(ev) => setSeoKeywordsEn(ev.target.value)}
                      className="form-control"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Description</label>
                    <input
                      value={seoDescEn}
                      onChange={(ev) => setSeoDescEn(ev.target.value)}
                      className="form-control"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Title</label>
                    <input
                      value={seoTitleEn}
                      onChange={(ev) => setSeoTitleEn(ev.target.value)}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              {/* </>
              ) : (
                <> */}
              <div className="card mb-3">
                <h5 className="card-header">Asthetics By Aster SEO (Ar)</h5>

                <div class="card-body">
                  <div className="mb-3">
                    <label className="form-label">Keywords</label>
                    <input
                      value={seoKeywordsAr}
                      onChange={(ev) => setSeoKeywordsAr(ev.target.value)}
                      className="form-control"
                      dir="rtl"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Description</label>
                    <input
                      value={seoDescAr}
                      onChange={(ev) => setSeoDescAr(ev.target.value)}
                      className="form-control"
                      dir="rtl"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Title</label>
                    <input
                      value={seoTitleAr}
                      onChange={(ev) => setSeoTitleAr(ev.target.value)}
                      className="form-control"
                      dir="rtl"
                    />
                  </div>
                </div>
              </div>
              {/* </>
              )} */}
              <div class="pt-4">
                <button
                  type="submit"
                  class="btn btn-primary me-sm-3 me-1 waves-effect waves-light"
                >
                  {t("Submit")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorSpeakForm;
