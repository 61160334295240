import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../../../i18n";
import { useSelector } from "react-redux";
import axios from "axios";
import Select from "react-select";
import { url, Image } from "../../Common/Url";
import { ToastContainer, toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Alert } from "react-bootstrap";
import FormServices from "../../../Services/FormServices";
import { Editor } from "@tinymce/tinymce-react";

const AddNews = ({ type }) => {
  const params = useParams();
  const { id } = params;

  const { token } = useSelector((state) => state.user);
  const { t, i18n } = useTranslation();
  const [activeLanguage, setActiveLanguage] = useState(i18n.language);
  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");
  const [description, setDescription] = useState("");
  const [metatitle, setMetatitle] = useState("");
  const [metadescription, setMetadescription] = useState("");
  const [metakeywords, setMetakeywords] = useState("");
  const [slug, setSlug] = useState("");
  const [contactForm, setContactForm] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState();
  const [doctors, setDoctors] = useState([]);
  const [externalLink, setExternalLink] = useState("");
  const fetchDoctors = async () => {
    try {
      const data = await FormServices.fetchDoctors();
      console.log(data);
      setDoctors(data);
    } catch (err) {
      console.log(err.message);
    }
  };
  useEffect(() => {
    fetchDoctors();
  }, []);
  const handleDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file.type.startsWith("image/")) {
      setImage(file);
    } else {
      toast.error("Invalid file type. Please upload an image file.");
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: ["image/*"],
    onDrop: handleDrop,
  });

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setActiveLanguage(lng);
  };

  const handleBlog = async () => {
    if (!title) {
      return toast.error("Please Enter Title");
    }

    try {
      const { data } = await axios.post(
        `${url}/api/v1/news/add`,
        {
          lan: activeLanguage,
          title: title,
          image: image,
          description: description,
          metatitle: metatitle,
          metadescription: metadescription,
          metakeywords: metakeywords,
          slug: slug,
          externalLink: externalLink,
          doctor: selectedDoctor,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            token: `${token}`,
          },
        }
      );

      toast.success(data.message);
      if (data.status === true) {
        window.location.reload();
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (id) {
      const getStepData = async () => {
        const { data } = await axios.get(`${url}/api/v1/news/get/${id}`, {
          headers: {
            token: `${token}`,
          },
        });

        console.log(data.data);
        if (activeLanguage === "ar") {
          setDescription(data.data.ar_description);
          setTitle(data.data.ar_title);
          setImage(data.data.ar_image);
          setMetatitle(data.data.ar_metatitle);
          setMetakeywords(data.data.ar_metakeywords);
          setMetadescription(data.data.ar_metadescription);
          setSlug(data.data.ar_slug);
          setExternalLink(data.data.ar_external_link);
          setSelectedDoctor(data.data.ar_doctor);
        } else {
          setDescription(data.data.en_description);
          setTitle(data.data.en_title);
          setImage(data.data.en_image);
          setMetatitle(data.data.en_metatitle);
          setMetakeywords(data.data.en_metakeywords);
          setMetadescription(data.data.en_metadescription);
          setSlug(data.data.en_slug);
          setExternalLink(data.data.en_external_link);
          setSelectedDoctor(data.data.en_doctor);
        }
      };
      getStepData();
    } else {
      setDescription("");
      setTitle("");
      setImage("");
      setMetatitle("");
      setMetakeywords("");
      setMetadescription("");
      setContactForm(false);
      setSlug("");
      setExternalLink("");
    }
  }, [id, activeLanguage]);

  const handleStepUpdate = async () => {
    try {
      const { data } = await axios.put(
        `${url}/api/v1/news/update/${id}`,
        {
          lan: activeLanguage,
          title: title,
          image: image,
          description: description,
          metatitle: metatitle,
          metadescription: metadescription,
          metakeywords: metakeywords,
          slug: slug,
          externalLink: externalLink,
          doctor: selectedDoctor,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            token: `${token}`,
          },
        }
      );

      toast.success(data.message);
      if (data.status === true) {
        window.location.reload();
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleTitle = (e) => {
    setTitle(e.target.value);
    let stringWithoutSpaces = e.target.value.replace(/\s+/g, "-");
    setSlug(stringWithoutSpaces.toLowerCase());
  };

  return (
    <>
      <div className="col-lg-8">
        <div className="card mb-3">
          <ToastContainer />
          <div className="card-header pt-2">
            <h4>{id ? `Edit news ${title}` : "Add news"}</h4>

            <ul className="nav nav-tabs card-header-tabs" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    activeLanguage === "en" ? "active" : ""
                  }`}
                  onClick={() => changeLanguage("en")}
                >
                  English
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    activeLanguage === "ar" ? "active" : ""
                  }`}
                  onClick={() => changeLanguage("ar")}
                >
                  Arabic
                </button>
              </li>
            </ul>
          </div>

          <div className="tab-content">
            <div className="tab-pane fade active show" role="tabpanel">
              <div>
                <div className="row g-3">
                  <div className="col-md-12">
                    <label className="form-label">{t("Title")}</label>
                    <input
                      type="text"
                      dir={activeLanguage == "ar" ? "rtl" : "ltr"}
                      className="form-control"
                      name="name"
                      value={title}
                      onChange={handleTitle}
                    />
                  </div>

                  <div className="col-md-12">
                    <label className="form-label">{t("Description")}</label>
                    <Editor
                      apiKey="8v2iaduxz30oj5ayfo3tw8xr5477h08594avuno5p2df6muf"
                      value={description}
                      onEditorChange={(newContent) =>
                        setDescription(newContent)
                      }
                      init={{
                        height: 500,
                        menubar: true,
                        plugins: [
                          "advlist autolink lists link image charmap print preview anchor",
                          "searchreplace visualblocks code fullscreen",
                          "insertdatetime media table paste code help wordcount",
                        ],
                        toolbar:
                          "undo redo | formatselect | bold italic | " +
                          "link bullist numlist outdent indent | " +
                          "image blockquote insertdatetime table media | " +
                          "alignleft aligncenter alignright alignjustify | " +
                          "removeformat selectall",
                        directionality: activeLanguage === "ar" ? "rtl" : "ltr",
                        language: activeLanguage === "ar" ? "ar" : "en",
                        content_style:
                          "body { font-family:Arial, sans-serif; font-size:14px }",
                        branding: false,
                      }}
                    />
                  </div>

                  <div className="col-md-12">
                    <label className="form-label">{t("Meta Title")}</label>
                    <input
                      type="text"
                      dir={activeLanguage == "ar" ? "rtl" : "ltr"}
                      className="form-control"
                      name="name"
                      value={metatitle}
                      onChange={(e) => setMetatitle(e.target.value)}
                    />
                  </div>
                  <div className="col-md-12">
                    <label className="form-label">
                      {t("Meta Description")}
                    </label>
                    <input
                      type="text"
                      dir={activeLanguage == "ar" ? "rtl" : "ltr"}
                      className="form-control"
                      name="name"
                      value={metadescription}
                      onChange={(e) => setMetadescription(e.target.value)}
                    />
                  </div>
                  <div className="col-md-12">
                    <label className="form-label">{t("Meta Keywords")}</label>
                    <input
                      type="text"
                      dir={activeLanguage == "ar" ? "rtl" : "ltr"}
                      className="form-control"
                      name="name"
                      value={metakeywords}
                      onChange={(e) => setMetakeywords(e.target.value)}
                    />
                  </div>
                  <div className="col-md-12">
                    <label className="form-label">{t("slug")}</label>
                    <input
                      type="text"
                      dir={activeLanguage == "ar" ? "rtl" : "ltr"}
                      className="form-control"
                      name="name"
                      value={slug}
                      onChange={(e) => setSlug(e.target.value)}
                    />
                  </div>
                  <div className="col-md-12">
                    <label className="form-label">{t("External Link")}</label>
                    <input
                      placeholder="Enter any external link for the news"
                      type="text"
                      dir={activeLanguage == "ar" ? "rtl" : "ltr"}
                      className="form-control"
                      name="name"
                      value={externalLink}
                      onChange={(e) => setExternalLink(e.target.value)}
                    />
                  </div>
                  <div
                    className="col-md-12"
                    style={{ display: "flex", gap: "2%" }}
                  ></div>
                  <div className="mb-3">
                    <label className="form-label">{t("Doctors")}</label>
                    <Select
                      dir="rtl"
                      name="doctorDetails.clinics"
                      value={selectedDoctor}
                      options={doctors.map((doctor) => ({
                        value: doctor?._id,
                        label: doctor?.[i18n.language]?.title,
                      }))}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(selectedOption) => {
                        console.log(selectedOption);
                        setSelectedDoctor(selectedOption);
                      }}
                    />
                  </div>
                </div>
                <div className="pt-4">
                  {id ? (
                    <button
                      type="submit"
                      className="btn btn-primary me-sm-3 me-1 waves-effect waves-light"
                      onClick={() => handleStepUpdate()}
                    >
                      {t("Update")}
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-primary me-sm-3 me-1 waves-effect waves-light"
                      onClick={handleBlog}
                    >
                      {t("Submit")}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="card mb-3">
          <div className="card-body">
            <div className="row g-3">
              <div className="col-md-12">
                <label className="form-label">{t("Image")}</label>

                <div {...getRootProps()} style={dropzoneStyles}>
                  <input {...getInputProps()} />
                  <p>
                    {t(
                      "Drag and drop an image file here, or click to select one"
                    )}
                  </p>
                </div>
                {image && image.path}
                <div
                  className=""
                  style={{ textAlign: "center", marginTop: "15px" }}
                >
                  {image && (
                    <img src={`${Image}/${image}`} width="200px" alt="Blog" />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const dropzoneStyles = {
  border: "2px dashed #cccccc",
  borderRadius: "4px",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
};

export default AddNews;
