import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Editor } from "@tinymce/tinymce-react";
import "../../i18n";

import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { url } from "../../Components/Common/Url";

const DescriptionForm = () => {
  const { token } = useSelector((state) => state.user);
  const { t, i18n } = useTranslation();
  const [activeLanguage, setActiveLanguage] = useState(i18n.language);
  const [textEditor, setTextEditor] = useState("");
  const [seoTitleEn, setSeoTitleEn] = useState("");
  const [seoKeywordsEn, setSeoKeywordsEn] = useState("");
  const [seoDescEn, setSeoDescEn] = useState("");
  const [seoTitleAr, setSeoTitleAr] = useState("");
  const [seoKeywordsAr, setSeoKeywordsAr] = useState("");
  const [seoDescAr, setSeoDescAr] = useState("");
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setActiveLanguage(lng);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch(
      `${url}/api/v1/asterathome/description/update/`,
      {
        method: "PUT",
        headers: {
          token: `${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          lan: activeLanguage,
          textEditor,
          seoTitleEn,
          seoKeywordsEn,
          seoDescEn,
          seoTitleAr,
          seoKeywordsAr,
          seoDescAr,
        }),
      }
    );
    const result = await response.json();

    toast(result.message);
    if (result.status == true) {
      setInterval(() => {
        window.location.reload();
      }, 3000);
    }
  };

  const get_contact_detail = async () => {
    const response = await fetch(`${url}/api/v1/asterathome/description/get/`, {
      method: "GET",
      headers: {
        token: `${token}`,
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();
    console.log(result);
    if (activeLanguage == "ar") {
      setTextEditor(result.data.ar_description);
      setSeoKeywordsAr(result.data.seoKeywordsAr);
      setSeoDescAr(result.data.seoDescAr);
      setSeoTitleAr(result.data.seoTitleAr);
    } else {
      setTextEditor(result.data.en_description);
      setSeoKeywordsEn(result.data.seoKeywordsEn);
      setSeoDescEn(result.data.seoDescEn);
      setSeoTitleEn(result.data.seoTitleEn);
    }
  };

  useEffect(() => {
    get_contact_detail();
  }, [activeLanguage]);

  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <ToastContainer />
      <div className="row">
        <div className="col">
          <div className="card mb-3">
            <div className="card-header pt-2">
              <ul className="nav nav-tabs card-header-tabs" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${
                      activeLanguage === "en" ? "active" : ""
                    }`}
                    onClick={() => changeLanguage("en")}
                  >
                    English
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${
                      activeLanguage === "ar" ? "active" : ""
                    }`}
                    onClick={() => changeLanguage("ar")}
                  >
                    Arabic
                  </button>
                </li>
              </ul>
            </div>

            <div className="tab-content">
              <div className="tab-pane fade active show" role="tabpanel">
                <form onSubmit={handleSubmit}>
                  <div className="row g-3">
                    <div className="col-md-12">
                      <label className="form-label">{t("Description")}</label>
                      <Editor
                        apiKey="8v2iaduxz30oj5ayfo3tw8xr5477h08594avuno5p2df6muf" // TinyMCE API key
                        initialValue={textEditor}
                        init={{
                          language: activeLanguage === "ar" ? "ar" : "en", // UI language
                          directionality:
                            activeLanguage === "ar" ? "rtl" : "ltr", // Content direction
                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code help wordcount",
                          ],
                          toolbar:
                            "heading | bold italic | link image | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | blockquote | insertTable | mediaEmbed | undo redo | selectAll",
                          heading: {
                            options: [
                              {
                                model: "paragraph",
                                title: "Paragraph",
                                class: "ck-heading_paragraph",
                              },
                              {
                                model: "heading1",
                                view: "h1",
                                title: "Heading 1",
                                class: "ck-heading_heading1",
                              },
                              {
                                model: "heading2",
                                view: "h2",
                                title: "Heading 2",
                                class: "ck-heading_heading2",
                              },
                              {
                                model: "heading3",
                                view: "h3",
                                title: "Heading 3",
                                class: "ck-heading_heading3",
                              },
                              {
                                model: "heading4",
                                view: "h4",
                                title: "Heading 4",
                                class: "ck-heading_heading4",
                              },
                              {
                                model: "heading5",
                                view: "h5",
                                title: "Heading 5",
                                class: "ck-heading_heading5",
                              },
                              {
                                model: "heading6",
                                view: "h6",
                                title: "Heading 6",
                                class: "ck-heading_heading6",
                              },
                            ],
                          },
                        }}
                        onEditorChange={(content) => {
                          setTextEditor(content); // Update the state with editor content
                        }}
                      />
                    </div>
                  </div>

                  {activeLanguage === "en" ? (
                    <>
                      <div className="card mb-3 mt-3">
                        <h5 className="card-header">Aster At Home SEO (En)</h5>

                        <div class="card-body">
                          <div className="mb-3">
                            <label className="form-label">Keywords</label>
                            <input
                              value={seoKeywordsEn}
                              onChange={(ev) =>
                                setSeoKeywordsEn(ev.target.value)
                              }
                              className="form-control"
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Description</label>
                            <input
                              value={seoDescEn}
                              onChange={(ev) => setSeoDescEn(ev.target.value)}
                              className="form-control"
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Title</label>
                            <input
                              value={seoTitleEn}
                              onChange={(ev) => setSeoTitleEn(ev.target.value)}
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="card mb-3">
                        <h5 className="card-header">Aster At Home SEO (Ar)</h5>

                        <div class="card-body">
                          <div className="mb-3">
                            <label className="form-label">Keywords</label>
                            <input
                              value={seoKeywordsAr}
                              onChange={(ev) =>
                                setSeoKeywordsAr(ev.target.value)
                              }
                              className="form-control"
                              dir="rtl"
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Description</label>
                            <input
                              value={seoDescAr}
                              onChange={(ev) => setSeoDescAr(ev.target.value)}
                              className="form-control"
                              dir="rtl"
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Title</label>
                            <input
                              value={seoTitleAr}
                              onChange={(ev) => setSeoTitleAr(ev.target.value)}
                              className="form-control"
                              dir="rtl"
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  <div class="pt-4">
                    <button
                      type="submit"
                      class="btn btn-primary me-sm-3 me-1 waves-effect waves-light"
                    >
                      {t("Submit")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DescriptionForm;
