import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Editor } from "@tinymce/tinymce-react";
import "../../i18n";

import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { url } from "../../Components/Common/Url";

const GerdRiskForm = () => {
  const { token } = useSelector((state) => state.user);
  const { t, i18n } = useTranslation();
  const [activeLanguage, setActiveLanguage] = useState(i18n.language);
  const [textEditor, setTextEditor] = useState("");
  const [title, setTitle] = useState("");
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setActiveLanguage(lng);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch(`${url}/api/v1/healthtool/gerdrisk/update/`, {
      method: "PUT",
      headers: {
        token: `${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        lan: activeLanguage,
        textEditor,
        title,
      }),
    });
    const result = await response.json();

    toast(result.message);
    if (result.status == true) {
      setInterval(() => {
        window.location.reload();
      }, 3000);
    }
  };

  const get_healthtool_detail = async () => {
    const response = await fetch(`${url}/api/v1/healthtool/gerdrisk/get/`, {
      method: "GET",
      headers: {
        token: `${token}`,
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();
    console.log(result);
    if (activeLanguage == "ar") {
      setTitle(result.data.ar_title);
      setTextEditor(result.data.ar_description);
    } else {
      setTitle(result.data.en_title);
      setTextEditor(result.data.en_description);
    }
  };

  useEffect(() => {
    get_healthtool_detail();
  }, [activeLanguage]);

  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <ToastContainer />
      <div className="row">
        <div className="col">
          <div className="card mb-3">
            <div className="card-header pt-2">
              <ul className="nav nav-tabs card-header-tabs" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${
                      activeLanguage === "en" ? "active" : ""
                    }`}
                    onClick={() => changeLanguage("en")}
                  >
                    English
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${
                      activeLanguage === "ar" ? "active" : ""
                    }`}
                    onClick={() => changeLanguage("ar")}
                  >
                    Arabic
                  </button>
                </li>
              </ul>
            </div>

            <div className="tab-content">
              <div className="tab-pane fade active show" role="tabpanel">
                <form onSubmit={handleSubmit}>
                  <div className="row g-3">
                    <div className="col-lg-12">
                      <label className="form-label">{t("Title")}</label>
                      <input
                        type="text"
                        dir={activeLanguage === "ar" ? "rtl" : "ltr"}
                        className="form-control"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>
                    <div className="col-md-12">
                      <label className="form-label">{t("Description")}</label>
                      <Editor
                        key={`editor1-${activeLanguage}`}
                        apiKey="8v2iaduxz30oj5ayfo3tw8xr5477h08594avuno5p2df6muf" // Your TinyMCE API key
                        value={textEditor} // Current editor content
                        init={{
                          language: activeLanguage === "ar" ? "ar" : "en", // UI language
                          direction: activeLanguage === "ar" ? "rtl" : "ltr", // Content direction (RTL for Arabic)
                          plugins: [
                            "link",
                            "image",
                            "lists",
                            "table",
                            "media",
                            "blockquote",
                            "undo",
                            "redo",
                          ],
                          toolbar: [
                            "heading",
                            "|",
                            "bold",
                            "italic",
                            "|",
                            "link",
                            "bullist",
                            "numlist",
                            "|",
                            "indent",
                            "outdent",
                            "|",
                            "image",
                            "blockquote",
                            "table",
                            "media",
                            "|",
                            "undo",
                            "redo",
                            "|",
                            "selectall",
                          ],
                          style_formats: [
                            { title: "Paragraph", format: "p" },
                            { title: "Heading 1", format: "h1" },
                            { title: "Heading 2", format: "h2" },
                            { title: "Heading 3", format: "h3" },
                            { title: "Heading 4", format: "h4" },
                            { title: "Heading 5", format: "h5" },
                            { title: "Heading 6", format: "h6" },
                          ],
                          content_style:
                            "body { font-family: Arial, sans-serif; font-size: 14px; }", // Optional: style for content
                        }}
                        onEditorChange={(content, editor) => {
                          setTextEditor(content); // Update the textEditor state with the new content
                        }}
                      />
                    </div>
                  </div>
                  <div class="pt-4">
                    <button
                      type="submit"
                      class="btn btn-primary me-sm-3 me-1 waves-effect waves-light"
                    >
                      {t("Submit")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GerdRiskForm;
