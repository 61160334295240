import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../../i18n";
import { useSelector } from "react-redux";
import axios from "axios";
import { url, Image } from "../../Components/Common/Url";
import { ToastContainer, toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import PdfUpload from "./../PdfUpload";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";

const StepForm = () => {
  const params = useParams();
  const { id } = params;

  const { token } = useSelector((state) => state.user);
  const { t, i18n } = useTranslation();
  const [activeLanguage, setActiveLanguage] = useState(i18n.language);
  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");
  const [description, setDescription] = useState("");

  const navigate = useNavigate();

  const handleDrop = useCallback((acceptedFiles) => {
    // Assuming you only want to handle one file at a time
    const file = acceptedFiles[0];
    if (file.type.startsWith("image/")) {
      // Do something with the valid image file
      console.log("Valid image file:", file);
      setImage(file);
    } else {
      // Handle invalid file type
      toast.error("Invalid file type. Please upload an image file.");
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: ["image/*"],
    onDrop: handleDrop,
  });

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setActiveLanguage(lng);
  };

  const handleStep = async () => {
    if (!title) {
      return toast.error("Please Enter Title");
    }
    const formData = new FormData();
    formData.append("lan", activeLanguage);
    formData.append("title", title);
    formData.append("image", image);
    formData.append("description", description);

    try {
      const { data } = await axios.post(
        `${url}/api/v1/teleconsultant/step/add`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            token: `${token}`,
          },
        }
      );

      toast.success(data.message);
      if (data.status == true) {
        window.location.reload();
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (id) {
      const get_step_data = async () => {
        const { data } = await axios.get(
          `${url}/api/v1/teleconsultant/step/get/${id}`,
          {
            headers: {
              token: `${token}`,
            },
          }
        );
        console.log(data);
        if (activeLanguage === "ar") {
          setDescription(data.data.ar_description);
          setTitle(data.data.ar_title);
          setImage(data.data.ar_image);
        } else {
          setDescription(data.data.en_description);
          setTitle(data.data.en_title);
          setImage(data.data.en_image);
        }
      };
      get_step_data();
    } else {
      setDescription("");
      setTitle("");
      setImage("");
    }
  }, [id, activeLanguage]);

  const handleStepUpdate = async () => {
    const formData = new FormData();
    formData.append("lan", activeLanguage);
    formData.append("title", title);
    formData.append("image", image);
    formData.append("description", description);
    try {
      const { data } = await axios.put(
        `${url}/api/v1/teleconsultant/step/update/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            token: `${token}`,
          },
        }
      );

      toast.success(data.message);
      if (data.status == true) {
        window.location.reload();
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className="col-md-4">
      <ToastContainer />
      <div className="card mb-3">
        <div className="card-header pt-2">
          <ul className="nav nav-tabs card-header-tabs" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${
                  activeLanguage === "en" ? "active" : ""
                }`}
                onClick={() => changeLanguage("en")}
              >
                English
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${
                  activeLanguage === "ar" ? "active" : ""
                }`}
                onClick={() => changeLanguage("ar")}
              >
                Arabic
              </button>
            </li>
          </ul>
        </div>

        <div className="tab-content">
          <div className="tab-pane fade active show" role="tabpanel">
            <div>
              <div className="row g-3">
                <div className="col-md-12">
                  <label className="form-label">{t("Title")}</label>
                  <input
                    dir={activeLanguage === "ar" ? "rtl" : "ltr"}
                    type="text"
                    className="form-control"
                    name="name"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
                <div className="col-md-12">
                  <label className="form-label">{t("Image")}</label>
                  <br />

                  <div {...getRootProps()} style={dropzoneStyles}>
                    <input {...getInputProps()} />
                    <p>
                      {t(
                        "Drag and drop an image file here, or click to select one"
                      )}
                    </p>
                  </div>
                  {image && image.path}

                  {image && (
                    <img
                      src={`${Image}/${image}`}
                      width={"250px"}
                      className="mt-4"
                    />
                  )}
                </div>

                <div className="col-md-12">
                  <label className="form-label">{t("Description")}</label>
                  <br />
                  <Editor
                    apiKey="8v2iaduxz30oj5ayfo3tw8xr5477h08594avuno5p2df6muf"
                    key={`editor1-${activeLanguage}`}
                    init={{
                      language: activeLanguage === "ar" ? "ar" : "en", // UI and content language
                      direction: activeLanguage === "ar" ? "rtl" : "ltr", // Content direction
                      toolbar: [
                        "heading",
                        "|",
                        "bold",
                        "italic",
                        "|",
                        "link",
                        "bullist",
                        "numlist",
                        "|",
                        "indent",
                        "outdent",
                        "|",
                        "image",
                        "blockquote",
                        "table",
                        "media",
                        "|",
                        "undo",
                        "redo",
                        "|",
                        "selectall",
                      ],
                      plugins: ["image", "table", "link", "media", "lists"],
                      style_formats: [
                        {
                          title: "Paragraph",
                          block: "p",
                          classes: "ck-heading_paragraph",
                        },
                        {
                          title: "Heading 1",
                          block: "h1",
                          classes: "ck-heading_heading1",
                        },
                        {
                          title: "Heading 2",
                          block: "h2",
                          classes: "ck-heading_heading2",
                        },
                        {
                          title: "Heading 3",
                          block: "h3",
                          classes: "ck-heading_heading3",
                        },
                        {
                          title: "Heading 4",
                          block: "h4",
                          classes: "ck-heading_heading4",
                        },
                        {
                          title: "Heading 5",
                          block: "h5",
                          classes: "ck-heading_heading5",
                        },
                        {
                          title: "Heading 6",
                          block: "h6",
                          classes: "ck-heading_heading6",
                        },
                      ],
                    }}
                    value={description}
                    onEditorChange={(newValue) => setDescription(newValue)} // Update state with editor content
                  />
                </div>
              </div>
              <div class="pt-4">
                {id ? (
                  <button
                    type="submit"
                    class="btn btn-primary me-sm-3 me-1 waves-effect waves-light"
                    onClick={handleStepUpdate}
                  >
                    {t("Update")}
                  </button>
                ) : (
                  <button
                    type="submit"
                    class="btn btn-primary me-sm-3 me-1 waves-effect waves-light"
                    onClick={handleStep}
                  >
                    {t("Submit")}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const dropzoneStyles = {
  border: "2px dashed #cccccc",
  borderRadius: "4px",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
};

export default StepForm;
