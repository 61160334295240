import React, { useEffect, useState, useRef } from "react";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import { Form as BootstrapForm, Button, Col, Row } from "react-bootstrap";
import { Editor } from "@tinymce/tinymce-react";
import ImageDropzone from "./Fields/ImageDropzone";
import TimePicker from "react-time-picker";
import FormServices from "../Services/FormServices";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import Select from "react-select";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import { assetUrl } from "../Components/Common/Url";

const ArEditClinicForm = () => {
  const selectAreaRef = useRef();

  const { id } = useParams();

  const [facilities, setFacilities] = useState([]);
  const [specialities, setSpecialities] = useState([]);
  const [locations, setLocations] = useState([]);
  const [cities, setCities] = useState([]);
  const [areas, setAreas] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [allDoctors, setAllDoctors] = useState([]);

  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState({
    title: "",
    slug: "",
    description: "",
    status: "published",
    facilities: [],
    specialities: [],
    location: "",
    city: "",
    area: "",
    featureImage: "",
    insurance: false,
    seo: {
      keywords: "",
      description: "",
      title: "",
    },
    details: {
      advertisementImage: "",
      link: "",
      bookNowBtn: "",
      landmark: "",
      address: "",
      longitude: "",
      latitude: "",
      phone: "",
      mobile: "",
      email: "",
      gallery: [],
    },
    timings: [
      {
        day: "",
        fromTimeFirstHalfHH: "",
        fromTimeFirstHalfMM: "",
        fromTimeFirstHalfampm: "",
        toTimeFirstHalfHH: "",
        toTimeFirstHalfMM: "",
        toTimeFirstHalfampm: "",

        fromTimeSecondHalfHH: "",
        fromTimeSecondHalfMM: "",
        fromTimeSecondHalfampm: "",
        toTimeSecondHalfHH: "",
        toTimeSecondHalfMM: "",
        toTimeSecondHalfampm: "",
        offDay: false,
      },
    ],
    doctors: {
      doctors: [],
      careGivers: "",
      is24Hours: false,
    },
    unitIncharge: {
      isActive: false,
      title: "",
      doctorImage: "",
      bgImage: "",
      text: "",
    },
    testimonials: [
      {
        image: "",
        title: "",
        testimonial: "",
        address: "",
      },
    ],
  });

  const fetchClinic = async (id) => {
    try {
      const data = await FormServices.getClinicById(id);
      console.log(data);
      setInitialValues({
        title: data?.ar?.title || "",
        slug: data?.slug || "",
        description: data?.ar?.description || "",
        status: data?.ar?.status || "",
        facilities: data?.ar?.facilities || [],
        specialities: data?.ar?.specialities || [],
        location: data?.ar?.locationId || null,
        city: data?.ar?.cityId || null,
        area: data?.ar?.areaId || null,
        featureImage: data?.ar?.featureImage
          ? assetUrl + data.ar.featureImage
          : "",
        insurance: data.ar.insurance || false,
        details: {
          advertisementImage: data?.ar?.details?.advertisementImage
            ? assetUrl + data.ar.details.advertisementImage
            : "",
          link: data?.ar?.details?.link || "",
          bookNowBtn: data?.ar?.details?.bookNowBtn || "",
          landmark: data?.ar?.details?.landmark || "",
          address: data?.ar?.details?.address || "",
          longitude: data?.ar?.details?.longitude || "",
          latitude: data?.ar?.details?.latitude || "",
          phone: data?.ar?.details?.phone || "",
          mobile: data?.ar?.details?.mobile || "",
          email: data?.ar?.details?.email || "",
          gallery: data?.ar?.details?.gallery || [],
        },
        seo: {
          keywords: data?.ar?.seo?.keywords,
          description: data?.ar?.seo?.description,
          title: data?.ar?.seo?.title,
        },
        timings: data?.ar?.timings || [],
        doctors: {
          doctors: data?.ar?.doctors?.doctors || [],
          careGivers: data?.ar?.doctors?.careGivers || "",
          is24Hours: data?.ar?.doctors?.is24Hours || false,
        },
        unitIncharge: {
          isActive: data?.ar?.unitIncharge?.isActive || false,
          title: data?.ar?.unitIncharge?.title || "",
          doctorImage: data?.ar?.unitIncharge?.doctorImage
            ? assetUrl + data.ar.unitIncharge.doctorImage
            : "",
          bgImage: data?.ar?.unitIncharge?.bgImage
            ? assetUrl + data.ar.unitIncharge.bgImage
            : "",
          text: data?.ar?.unitIncharge?.text || "",
        },
        testimonials: data?.ar?.testimonials || [],
      });

      await fetchCitiesByLocation(data?.ar?.locationId);
      await fetchAreasByCity(data?.ar?.cityId);
    } catch (error) {
      toast.error("Error fetching clinic data");
    }
  };

  const fetchCitiesByLocation = async (locationId) => {
    try {
      const data = await FormServices.fetchCities(locationId);
      setCities(data);
    } catch (err) {
      console.error(err);
    }
  };
  const fetchDoctors = async () => {
    try {
      const data = await FormServices.fetchDoctors();
      setAllDoctors(data);
    } catch (err) {
      console.log(err.message);
    }
  };
  const fetchAreasByCity = async (cityId) => {
    try {
      const data = await FormServices.fetchAreas(cityId);
      setAreas(data);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchSpecialities = async () => {
    try {
      const data = await FormServices.fetchSpecialities();
      setSpecialities(data);
    } catch (err) {
    } finally {
    }
  };

  const fetchFacilities = async () => {
    try {
      const data = await FormServices.fetchFacilities();
      setFacilities(data);
    } catch (err) {
    } finally {
    }
  };

  const fetchLocations = async () => {
    try {
      const data = await FormServices.fetchLocations();
      setLocations(data);
    } catch (err) {
    } finally {
    }
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    slug: Yup.string().required("Slug is required"),
  });

  useEffect(() => {
    fetchFacilities();
    fetchSpecialities();
    fetchLocations();
    fetchDoctors();
    fetchClinic(id);
  }, [id]);

  const onSubmit = async (values) => {
    console.log("values", values);
    try {
      const response = await FormServices.updateArClinic(values, id);

      console.log(response);
      navigate(`/admin/clinics-list`);
      toast.success("Clinic has been updated successfully!");
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
    }
  };

  const [arTime, setArTime] = useState([
    "صباحاً",
    "ظهراً",
    "عصراً",
    "مساءً",
    "ليلاً ",
  ]);
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          setFieldValue,
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12 col-lg-8">
                <div className="card mb-3">
                  <div class="card-body">
                    <BootstrapForm.Group controlId="title" className="mb-3">
                      <BootstrapForm.Label>Title</BootstrapForm.Label>
                      <Field
                        dir="rtl"
                        placeholder="Enter Title Here"
                        name="title"
                        className="form-control"
                        onChange={(event) => {
                          setFieldValue("title", event.target.value);
                          setFieldValue(
                            "slug",
                            event.target.value
                              .split(" ")
                              .join("-")
                              .toLowerCase()
                          );
                        }}
                      />
                      {errors.title && touched.title ? (
                        <div className="text-danger">{errors.title}</div>
                      ) : null}
                    </BootstrapForm.Group>

                    <BootstrapForm.Group controlId="slug" className="mb-3">
                      <BootstrapForm.Label>Slug</BootstrapForm.Label>
                      <Field
                        dir="rtl"
                        placeholder="Enter Slug Here"
                        name="slug"
                        className="form-control"
                      />
                      {errors.slug && touched.slug ? (
                        <div className="text-danger">{errors.slug}</div>
                      ) : null}
                    </BootstrapForm.Group>

                    <BootstrapForm.Group
                      controlId="description"
                      className="mb-3"
                    >
                      <BootstrapForm.Label>Description</BootstrapForm.Label>
                      <Editor
                        apiKey="8v2iaduxz30oj5ayfo3tw8xr5477h08594avuno5p2df6muf" // Replace with your TinyMCE API Key
                        value={values.description} // Assuming Formik is being used
                        onEditorChange={(content) =>
                          setFieldValue("description", content)
                        }
                        init={{
                          height: 400,
                          language: "ar", // Arabic UI
                          directionality: "rtl", // RTL content
                          menubar: false,
                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code help wordcount",
                          ],
                          toolbar:
                            "undo redo | heading | bold italic | " +
                            "link bullist numlist | indent outdent | " +
                            "image media table blockquote | selectAll",
                          content_style:
                            "body { font-family: 'Arial', sans-serif; font-size: 14px; direction: rtl; }",
                        }}
                      />

                      {errors.description && touched.description ? (
                        <div className="text-danger">{errors.description}</div>
                      ) : null}
                    </BootstrapForm.Group>

                    <BootstrapForm.Group
                      controlId="featureImage"
                      className="mb-3"
                    >
                      <BootstrapForm.Label>Feature Image</BootstrapForm.Label>
                      <ImageDropzone
                        onFilesAdded={(acceptedFiles) => {
                          if (acceptedFiles.length > 0) {
                            console.log(acceptedFiles[0]);
                            setFieldValue("featureImage", acceptedFiles[0]);
                          }
                        }}
                      />

                      {initialValues?.featureImage && (
                        <div className="demo-inline-spacing">
                          <img
                            src={initialValues.featureImage}
                            alt="Uploaded"
                            style={{ maxWidth: "100px", height: "auto" }}
                          />
                        </div>
                      )}
                      {errors.featureImage && touched.featureImage ? (
                        <div className="text-danger">{errors.featureImage}</div>
                      ) : null}
                    </BootstrapForm.Group>
                  </div>
                </div>

                <div class="card mb-6 mb-3">
                  <div class="card-header d-flex justify-content-between align-items-center">
                    <h5 class="mb-0 card-title">Details</h5>
                  </div>
                  <div class="card-body">
                    {/* Details Section */}
                    <BootstrapForm.Group
                      controlId="details.advertisementImage"
                      className="mb-3"
                    >
                      <BootstrapForm.Label>
                        Advertisement Image
                      </BootstrapForm.Label>
                      <ImageDropzone
                        onFilesAdded={(acceptedFiles) => {
                          if (acceptedFiles.length > 0) {
                            console.log(acceptedFiles[0]);
                            setFieldValue(
                              "details.advertisementImage",
                              acceptedFiles[0]
                            );
                          }
                        }}
                      />
                      {initialValues?.details?.advertisementImage && (
                        <div className="demo-inline-spacing">
                          <img
                            src={initialValues.details.advertisementImage}
                            alt="Uploaded"
                            style={{ maxWidth: "100px", height: "auto" }}
                          />
                        </div>
                      )}
                    </BootstrapForm.Group>

                    <BootstrapForm.Group
                      controlId="details.link"
                      className="mb-3"
                    >
                      <BootstrapForm.Label>Link</BootstrapForm.Label>
                      <Field
                        dir="rtl"
                        placeholder="Enter Link Here"
                        name="details.link"
                        className="form-control"
                      />
                    </BootstrapForm.Group>

                    <BootstrapForm.Group
                      controlId="details.bookNowBtn"
                      className="mb-3"
                    >
                      <BootstrapForm.Label>Book Now Button</BootstrapForm.Label>
                      <Field
                        dir="rtl"
                        placeholder="Enter BookNow link Here"
                        name="details.bookNowBtn"
                        className="form-control"
                      />
                    </BootstrapForm.Group>

                    <BootstrapForm.Group
                      controlId="details.landmark"
                      className="mb-3"
                    >
                      <BootstrapForm.Label>Landmark</BootstrapForm.Label>
                      <Field
                        dir="rtl"
                        placeholder="Enter Landmark Here"
                        name="details.landmark"
                        className="form-control"
                      />
                    </BootstrapForm.Group>

                    <BootstrapForm.Group
                      controlId="details.address"
                      className="mb-3"
                    >
                      <BootstrapForm.Label>Address</BootstrapForm.Label>
                      <Field
                        dir="rtl"
                        placeholder="Enter Address Here"
                        name="details.address"
                        className="form-control"
                      />
                    </BootstrapForm.Group>

                    <BootstrapForm.Group
                      controlId="details.longitude"
                      className="mb-3"
                    >
                      <BootstrapForm.Label>Longitude</BootstrapForm.Label>
                      <Field
                        dir="rtl"
                        placeholder="Enter Longitude Here"
                        name="details.longitude"
                        className="form-control"
                      />
                    </BootstrapForm.Group>

                    <BootstrapForm.Group
                      controlId="details.latitude"
                      className="mb-3"
                    >
                      <BootstrapForm.Label>Latitude</BootstrapForm.Label>
                      <Field
                        dir="rtl"
                        placeholder="Enter Latitude Here"
                        name="details.latitude"
                        className="form-control"
                      />
                    </BootstrapForm.Group>

                    <BootstrapForm.Group
                      controlId="details.phone"
                      className="mb-3"
                    >
                      <BootstrapForm.Label>Telephone</BootstrapForm.Label>
                      <Field
                        style={{ direction: "ltr", textAlign: "right" }}
                        placeholder="Enter Telephone Here"
                        name="details.phone"
                        className="form-control"
                      />
                    </BootstrapForm.Group>

                    <BootstrapForm.Group
                      controlId="details.mobile"
                      className="mb-3"
                    >
                      <BootstrapForm.Label>Fax</BootstrapForm.Label>
                      <Field
                        style={{ direction: "ltr", textAlign: "right" }}
                        placeholder="Enter Fax Here"
                        name="details.mobile"
                        className="form-control"
                      />
                    </BootstrapForm.Group>

                    <BootstrapForm.Group
                      controlId="details.email"
                      className="mb-3"
                    >
                      <BootstrapForm.Label>Email</BootstrapForm.Label>
                      <Field
                        dir="rtl"
                        placeholder="Enter Email Here"
                        name="details.email"
                        className="form-control"
                      />
                    </BootstrapForm.Group>

                    <BootstrapForm.Group
                      controlId="details.gallery"
                      className="mb-3"
                    >
                      <BootstrapForm.Label>Gallery</BootstrapForm.Label>

                      <ImageDropzone
                        onFilesAdded={(acceptedFiles) => {
                          if (acceptedFiles.length > 0) {
                            const updatedGallery = [
                              ...values.details.gallery,
                              ...acceptedFiles,
                            ];
                            setFieldValue("details.gallery", updatedGallery);
                          }
                        }}
                      />

                      <div className="d-flex gap-3">
                        {initialValues?.details?.gallery.map(
                          (uploadedgallery, index) => (
                            <div className="demo-inline-spacing" key={index}>
                              {uploadedgallery && uploadedgallery !== "" && (
                                <img
                                  src={assetUrl + uploadedgallery}
                                  alt="Uploaded"
                                />
                              )}
                            </div>
                          )
                        )}
                      </div>

                      {errors.details?.gallery && touched.details?.gallery ? (
                        <div className="text-danger">
                          {errors.details.gallery}
                        </div>
                      ) : null}
                    </BootstrapForm.Group>
                    <BootstrapForm.Group controlId="insurance" className="mb-3">
                      <BootstrapForm.Label>Show Insurance</BootstrapForm.Label>
                      <div className="col-12">
                        <div className="form-check">
                          <Field
                            name="insurance"
                            type="checkbox"
                            className="form-check-input"
                          />
                          <label className="form-check-label"> Yes </label>
                        </div>
                      </div>
                    </BootstrapForm.Group>
                  </div>
                </div>

                <div className="card mb-3">
                  <h5 className="card-header">SEO</h5>

                  <div class="card-body">
                    <div className="mb-3">
                      <label className="form-label">Keywords</label>
                      <Field
                        className="form-control"
                        name="seo.keywords"
                        dir="rtl"
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Description</label>
                      <Field
                        className="form-control"
                        name="seo.description"
                        dir="rtl"
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Title</label>
                      <Field
                        className="form-control"
                        name="seo.title"
                        dir="rtl"
                      />
                    </div>
                  </div>
                </div>

                <div class="card mb-6 mb-3">
                  <div class="card-header d-flex justify-content-between align-items-center">
                    <h5 class="mb-0 card-title">Timings</h5>
                  </div>
                  <div class="card-body">
                    {/* Timings Section */}

                    <FieldArray name="timings">
                      {({ insert, remove, push }) => (
                        <div className="timings-container">
                          <Row>
                            <Col>
                              <BootstrapForm.Group className="mb-3">
                                <BootstrapForm.Label>Day</BootstrapForm.Label>
                              </BootstrapForm.Group>
                            </Col>
                            <Col sm={2}>
                              <BootstrapForm.Group className="mb-3">
                                <BootstrapForm.Label>
                                  From Time (First Half)
                                </BootstrapForm.Label>
                              </BootstrapForm.Group>
                            </Col>
                            <Col sm={2}>
                              <BootstrapForm.Group className="mb-3">
                                <BootstrapForm.Label>
                                  To Time (First Half)
                                </BootstrapForm.Label>
                              </BootstrapForm.Group>
                            </Col>
                            <Col sm={2}>
                              <BootstrapForm.Group className="mb-3">
                                <BootstrapForm.Label>
                                  From Time (Second Half)
                                </BootstrapForm.Label>
                              </BootstrapForm.Group>
                            </Col>
                            <Col sm={2}>
                              <BootstrapForm.Group className="mb-3">
                                <BootstrapForm.Label>
                                  To Time (Second Half)
                                </BootstrapForm.Label>
                              </BootstrapForm.Group>
                            </Col>
                            <Col sm={2}>
                              <BootstrapForm.Group className="mb-3">
                                <BootstrapForm.Label>
                                  Off Day
                                </BootstrapForm.Label>
                              </BootstrapForm.Group>
                            </Col>
                          </Row>
                          {values.timings.map((timing, index) => (
                            // <div key={index}>
                            //   <Row>
                            //     <Col>
                            //       <BootstrapForm.Group
                            //         controlId={`timings.${index}.day`}
                            //         className="mb-3"
                            //       >
                            //         <Field
                            //           dir="rtl"
                            //           name={`timings.${index}.day`}
                            //           as="select"
                            //           className="form-control"
                            //         >
                            //           <option value="">Select Day</option>
                            //           <option value="Sunday">Sunday</option>
                            //           <option value="Monday">Monday</option>
                            //           <option value="Tuesday">Tuesday</option>
                            //           <option value="Wednesday">
                            //             Wednesday
                            //           </option>
                            //           <option value="Thursday">Thursday</option>
                            //           <option value="Friday">Friday</option>
                            //           <option value="Saturday">Saturday</option>
                            //         </Field>
                            //       </BootstrapForm.Group>
                            //     </Col>
                            //     <Col sm={2}>
                            //       <BootstrapForm.Group
                            //         controlId={`timings.${index}.fromTimeFirstHalf`}
                            //         className="mb-3"
                            //       >
                            //         <TimePicker
                            //           format="h:m a"
                            //           onChange={(value) =>
                            //             setFieldValue(
                            //               `timings.${index}.fromTimeFirstHalf`,
                            //               value
                            //             )
                            //           }
                            //           value={
                            //             values.timings[index].fromTimeFirstHalf
                            //           }
                            //         />
                            //       </BootstrapForm.Group>
                            //     </Col>
                            //     <Col sm={2}>
                            //       <BootstrapForm.Group
                            //         controlId={`timings.${index}.toTimeFirstHalf`}
                            //         className="mb-3"
                            //       >
                            //         <TimePicker
                            //           format="h:m a"
                            //           onChange={(value) =>
                            //             setFieldValue(
                            //               `timings.${index}.toTimeFirstHalf`,
                            //               value
                            //             )
                            //           }
                            //           value={
                            //             values.timings[index].toTimeFirstHalf
                            //           }
                            //         />
                            //       </BootstrapForm.Group>
                            //     </Col>
                            //     <Col sm={2}>
                            //       <BootstrapForm.Group
                            //         controlId={`timings.${index}.fromTimeSecondHalf`}
                            //         className="mb-3"
                            //       >
                            //         <TimePicker
                            //           format="h:m a"
                            //           onChange={(value) =>
                            //             setFieldValue(
                            //               `timings.${index}.fromTimeSecondHalf`,
                            //               value
                            //             )
                            //           }
                            //           value={
                            //             values.timings[index].fromTimeSecondHalf
                            //           }
                            //         />
                            //       </BootstrapForm.Group>
                            //     </Col>
                            //     <Col sm={2}>
                            //       <BootstrapForm.Group
                            //         controlId={`timings.${index}.toTimeSecondHalf`}
                            //         className="mb-3"
                            //       >
                            //         <TimePicker
                            //           format="h:m a"
                            //           onChange={(value) =>
                            //             setFieldValue(
                            //               `timings.${index}.toTimeSecondHalf`,
                            //               value
                            //             )
                            //           }
                            //           value={
                            //             values.timings[index].toTimeSecondHalf
                            //           }
                            //         />
                            //       </BootstrapForm.Group>
                            //     </Col>
                            //     <Col sm={2}>
                            //       <BootstrapForm.Group
                            //         controlId={`timings.${index}.offDay`}
                            //         className="mb-3"
                            //       >
                            //         <div className="col-12 d-flex align-items-center gap-2">
                            //           <div className="form-check">
                            //             <Field
                            //               dir="rtl"
                            //               name={`timings.${index}.offDay`}
                            //               type="checkbox"
                            //               className="form-check-input"
                            //             />
                            //             <label
                            //               className="form-check-label"
                            //               for="deliveryAdd"
                            //             >
                            //               {" "}
                            //               Yes{" "}
                            //             </label>
                            //           </div>
                            //           <span
                            //             class="badge badge-center rounded-pill bg-danger"
                            //             onClick={() => remove(index)}
                            //           >
                            //             {" "}
                            //             <i class="ti ti-x ti-xs"></i>
                            //           </span>
                            //         </div>
                            //       </BootstrapForm.Group>
                            //     </Col>
                            //   </Row>
                            // </div>
                            <div key={index}>
                              <Row>
                                <Col xs={2}>
                                  <BootstrapForm.Group
                                    controlId={`timings.${index}.day`}
                                    className="mb-3 dayselect"
                                  >
                                    <Field
                                      dir="rtl"
                                      name={`timings.${index}.day`}
                                      as="select"
                                      className="form-control"
                                    >
                                      <option value="">Select Day</option>
                                      <option value="Sunday">Sunday</option>
                                      <option value="Monday">Monday</option>
                                      <option value="Tuesday">Tuesday</option>
                                      <option value="Wednesday">
                                        Wednesday
                                      </option>
                                      <option value="Thursday">Thursday</option>
                                      <option value="Friday">Friday</option>
                                      <option value="Saturday">Saturday</option>
                                    </Field>
                                  </BootstrapForm.Group>
                                </Col>
                                <Col sm={2} className="timingmenues ">
                                  <BootstrapForm.Group
                                    controlId={`timings.${index}.fromTimeFirstHalf`}
                                    className="mb-3"
                                  >
                                    <div
                                      style={{
                                        height: "38px",
                                        border: "1px solid #dbdade",
                                        borderRadius: ".4vw",
                                        display: "flex",
                                        textAlign: "center",
                                      }}
                                    >
                                      <input
                                        style={{
                                          width: "55px",
                                          border: "none",
                                        }}
                                        type="number"
                                        name="fromTimeFirstHalfHH"
                                        id="fromTimeFirstHalfHH"
                                        minLength="0"
                                        maxLength="2"
                                        placeholder="HH"
                                        step="1"
                                        onChange={(e) =>
                                          setFieldValue(
                                            `timings.${index}.fromTimeFirstHalfHH`,
                                            e.target.value
                                          )
                                        }
                                        value={
                                          values.timings[index]
                                            .fromTimeFirstHalfHH
                                        }
                                      />
                                      <input
                                        style={{
                                          width: "55px",
                                          border: "none",
                                        }}
                                        type="number"
                                        name="fromTimeFirstHalfMM"
                                        id="fromTimeFirstHalfMM"
                                        placeholder="MM"
                                        minLength="0"
                                        maxLength="2"
                                        step="1"
                                        onChange={(e) =>
                                          setFieldValue(
                                            `timings.${index}.fromTimeFirstHalfMM`,
                                            e.target.value
                                          )
                                        }
                                        value={
                                          values.timings[index]
                                            .fromTimeFirstHalfMM
                                        }
                                      />
                                      <Field
                                        style={{
                                          width: "fit-content",
                                          border: "none",
                                        }}
                                        as="select"
                                        name="fromTimeFirstHalfampm"
                                        onChange={(ev) =>
                                          setFieldValue(
                                            `timings.${index}.fromTimeFirstHalfampm`,
                                            ev.target.value
                                          )
                                        }
                                        // className="form-select"
                                      >
                                        <option value="">--</option>
                                        {arTime &&
                                          arTime.map((_time, _index) => (
                                            <option
                                              key={_index}
                                              value={_time}
                                              selected={
                                                values.timings[index]
                                                  .fromTimeFirstHalfampm ===
                                                _time
                                              }
                                            >
                                              {_time}
                                            </option>
                                          ))}
                                      </Field>
                                    </div>
                                  </BootstrapForm.Group>
                                </Col>
                                <Col sm={2} className="timingmenues">
                                  <BootstrapForm.Group
                                    controlId={`timings.${index}.toTimeFirstHalf`}
                                    className="mb-3"
                                  >
                                    <div
                                      style={{
                                        height: "38px",
                                        border: "1px solid #dbdade",
                                        borderRadius: ".4vw",
                                        display: "flex",
                                        textAlign: "center",
                                      }}
                                    >
                                      <input
                                        style={{
                                          width: "55px",
                                          border: "none",
                                        }}
                                        type="number"
                                        name="toTimeFirstHalfHH"
                                        id="toTimeFirstHalfHH"
                                        minLength="0"
                                        maxLength="2"
                                        placeholder="HH"
                                        step="1"
                                        onChange={(e) =>
                                          setFieldValue(
                                            `timings.${index}.toTimeFirstHalfHH`,
                                            e.target.value
                                          )
                                        }
                                        value={
                                          values.timings[index]
                                            .toTimeFirstHalfHH
                                        }
                                      />
                                      <input
                                        style={{
                                          width: "55px",
                                          border: "none",
                                        }}
                                        type="number"
                                        name="toTimeFirstHalfMM"
                                        id="toTimeFirstHalfMM"
                                        minLength="0"
                                        placeholder="MM"
                                        maxLength="2"
                                        step="1"
                                        onChange={(e) =>
                                          setFieldValue(
                                            `timings.${index}.toTimeFirstHalfMM`,
                                            e.target.value
                                          )
                                        }
                                        value={
                                          values.timings[index]
                                            .toTimeFirstHalfMM
                                        }
                                      />
                                      <Field
                                        style={{
                                          width: "fit-content",
                                          border: "none",
                                        }}
                                        as="select"
                                        name="toTimeFirstHalfampm"
                                        onChange={(ev) =>
                                          setFieldValue(
                                            `timings.${index}.toTimeFirstHalfampm`,
                                            ev.target.value
                                          )
                                        }
                                      >
                                        <option value="">--</option>
                                        {arTime &&
                                          arTime.map((_time, _index) => (
                                            <option
                                              key={_index}
                                              value={_time}
                                              selected={
                                                values.timings[index]
                                                  .toTimeFirstHalfampm === _time
                                              }
                                            >
                                              {_time}
                                            </option>
                                          ))}
                                      </Field>
                                    </div>
                                  </BootstrapForm.Group>
                                </Col>
                                <Col sm={2} className="timingmenues">
                                  <BootstrapForm.Group
                                    controlId={`timings.${index}.fromTimeSecondHalf`}
                                    className="mb-3"
                                  >
                                    <div
                                      style={{
                                        height: "38px",
                                        border: "1px solid #dbdade",
                                        borderRadius: ".4vw",
                                        display: "flex",
                                        textAlign: "center",
                                      }}
                                    >
                                      <input
                                        style={{
                                          width: "55px",
                                          border: "none",
                                        }}
                                        type="number"
                                        name="fromTimeSecondHalfHH"
                                        placeholder="HH"
                                        id="fromTimeSecondHalfHH"
                                        minLength="0"
                                        maxLength="2"
                                        step="1"
                                        onChange={(e) =>
                                          setFieldValue(
                                            `timings.${index}.fromTimeSecondHalfHH`,
                                            e.target.value
                                          )
                                        }
                                        value={
                                          values.timings[index]
                                            .fromTimeSecondHalfHH
                                        }
                                      />
                                      <input
                                        style={{
                                          width: "55px",
                                          border: "none",
                                        }}
                                        type="number"
                                        name="fromTimeSecondHalfMM"
                                        id="fromTimeSecondHalfMM"
                                        placeholder="MM"
                                        minLength="0"
                                        maxLength="2"
                                        step="1"
                                        onChange={(e) =>
                                          setFieldValue(
                                            `timings.${index}.fromTimeSecondHalfMM`,
                                            e.target.value
                                          )
                                        }
                                        value={
                                          values.timings[index]
                                            .fromTimeSecondHalfMM
                                        }
                                      />
                                      <Field
                                        style={{
                                          width: "fit-content",
                                          border: "none",
                                        }}
                                        as="select"
                                        name="fromTimeSecondHalfampm"
                                        onChange={(ev) =>
                                          setFieldValue(
                                            `timings.${index}.fromTimeSecondHalfampm`,
                                            ev.target.value
                                          )
                                        }
                                        // className="form-select"
                                      >
                                        <option value="">--</option>
                                        {arTime &&
                                          arTime.map((_time, _index) => (
                                            <option
                                              key={_index}
                                              value={_time}
                                              selected={
                                                values.timings[index]
                                                  .fromTimeSecondHalfampm ===
                                                _time
                                              }
                                            >
                                              {_time}
                                            </option>
                                          ))}
                                      </Field>
                                    </div>
                                  </BootstrapForm.Group>
                                </Col>

                                <Col sm={2} className="timingmenues">
                                  <BootstrapForm.Group
                                    controlId={`timings.${index}.toTimeSecondHalf`}
                                    className="mb-3"
                                  >
                                    <div
                                      style={{
                                        height: "38px",
                                        border: "1px solid #dbdade",
                                        borderRadius: ".4vw",
                                        display: "flex",
                                        textAlign: "center",
                                      }}
                                    >
                                      <input
                                        style={{
                                          width: "55px",
                                          border: "none",
                                        }}
                                        type="number"
                                        name="toTimeSecondHalfHH"
                                        id="toTimeSecondHalfHH"
                                        placeholder="HH"
                                        minLength="0"
                                        maxLength="2"
                                        step="1"
                                        onChange={(e) =>
                                          setFieldValue(
                                            `timings.${index}.toTimeSecondHalfHH`,
                                            e.target.value
                                          )
                                        }
                                        value={
                                          values.timings[index]
                                            .toTimeSecondHalfHH
                                        }
                                      />
                                      <input
                                        style={{
                                          width: "55px",
                                          border: "none",
                                        }}
                                        type="number"
                                        name="toTimeSecondHalfMM"
                                        id="toTimeSecondHalfMM"
                                        placeholder="MM"
                                        minLength="0"
                                        maxLength="2"
                                        step="1"
                                        onChange={(e) =>
                                          setFieldValue(
                                            `timings.${index}.toTimeSecondHalfMM`,
                                            e.target.value
                                          )
                                        }
                                        value={
                                          values.timings[index]
                                            .toTimeSecondHalfMM
                                        }
                                      />
                                      <Field
                                        style={{
                                          width: "fit-content",
                                          border: "none",
                                        }}
                                        as="select"
                                        name="toTimeSecondHalfampm"
                                        onChange={(ev) =>
                                          setFieldValue(
                                            `timings.${index}.toTimeSecondHalfampm`,
                                            ev.target.value
                                          )
                                        }
                                        // className="form-select"
                                      >
                                        <option value="">--</option>
                                        {arTime &&
                                          arTime.map((_time, _index) => (
                                            <option
                                              key={_index}
                                              value={_time}
                                              selected={
                                                values.timings[index]
                                                  .toTimeSecondHalfampm ===
                                                _time
                                              }
                                            >
                                              {_time}
                                            </option>
                                          ))}
                                      </Field>
                                    </div>
                                  </BootstrapForm.Group>
                                </Col>
                                <Col sm={2}>
                                  <BootstrapForm.Group
                                    controlId={`timings.${index}.offDay`}
                                    className="mb-3"
                                  >
                                    <div className="col-12 d-flex align-items-center gap-2">
                                      <div className="form-check">
                                        <Field
                                          name={`timings.${index}.offDay`}
                                          type="checkbox"
                                          className="form-check-input"
                                        />
                                        <label className="form-check-label">
                                          {" "}
                                          Yes{" "}
                                        </label>
                                      </div>
                                      <span
                                        class="badge badge-center rounded-pill bg-danger"
                                        onClick={() => remove(index)}
                                      >
                                        {" "}
                                        <i class="ti ti-x ti-xs"></i>
                                      </span>
                                    </div>
                                  </BootstrapForm.Group>
                                </Col>
                              </Row>
                            </div>
                          ))}

                          <div class="mb-0">
                            <Button
                              size="sm"
                              className="btn btn-primary waves-effect waves-light mb-4"
                              variant="primary"
                              onClick={() =>
                                push({
                                  day: "",
                                  fromTimeFirstHalfHH: "",
                                  fromTimeFirstHalfMM: "",
                                  fromTimeFirstHalfampm: "",
                                  toTimeFirstHalfHH: "",
                                  toTimeFirstHalfMM: "",
                                  toTimeFirstHalfampm: "",

                                  fromTimeSecondHalfHH: "",
                                  fromTimeSecondHalfMM: "",
                                  fromTimeSecondHalfampm: "",
                                  toTimeSecondHalfHH: "",
                                  toTimeSecondHalfMM: "",
                                  toTimeSecondHalfampm: "",
                                  offDay: false,
                                })
                              }
                            >
                              <i className="ti ti-plus ti-xs me-2"></i>
                              <span className="align-middle">Add</span>
                            </Button>
                          </div>
                        </div>
                      )}
                    </FieldArray>
                  </div>
                </div>

                <div class="card mb-6 mb-3">
                  <div class="card-header d-flex justify-content-between align-items-center">
                    <h5 class="mb-0 card-title">Doctors</h5>
                  </div>
                  <div class="card-body">
                    {/* Doctors Section */}

                    <BootstrapForm.Group
                      controlId="doctors.doctors"
                      className="mb-3"
                    >
                      <BootstrapForm.Label>Doctors</BootstrapForm.Label>
                      <Select
                        isMulti
                        name="doctors.doctors"
                        options={allDoctors.map((doctor) => ({
                          value: doctor?._id,
                          label: doctor?.en?.title,
                        }))}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        // onChange={(selectedOptions) =>
                        //   setFieldValue("doctors.doctors", selectedOptions)
                        // }
                        value={values.doctors.doctors
                          .map((doctorId) =>
                            allDoctors.find((doctor) => doctor._id === doctorId)
                              ? {
                                  value: doctorId,
                                  label: allDoctors.find(
                                    (doctor) => doctor._id === doctorId
                                  ).en.title,
                                }
                              : null
                          )
                          .filter(Boolean)} // Map IDs to objects, filter out nulls
                        onChange={(selectedOptions) =>
                          setFieldValue(
                            "doctors.doctors",
                            selectedOptions.map((option) => option.value) // Only store IDs in state
                          )
                        }
                      />
                    </BootstrapForm.Group>

                    <BootstrapForm.Group
                      controlId="doctors.careGivers"
                      className="mb-3"
                    >
                      <BootstrapForm.Label>Care Givers</BootstrapForm.Label>
                      <Field
                        dir="rtl"
                        name="doctors.careGivers"
                        className="form-control"
                      />
                    </BootstrapForm.Group>

                    <BootstrapForm.Group
                      controlId="doctors.is24Hours"
                      className="mb-3"
                    >
                      <BootstrapForm.Label>24 Hours</BootstrapForm.Label>
                      <div className="col-12">
                        <div className="form-check">
                          <Field
                            dir="rtl"
                            name="doctors.is24Hours"
                            type="checkbox"
                            className="form-check-input"
                          />
                          <label className="form-check-label"> Yes </label>
                        </div>
                      </div>
                    </BootstrapForm.Group>
                  </div>
                </div>

                <div class="card mb-6 mb-3">
                  <div class="card-header d-flex justify-content-between align-items-center">
                    <h5 class="mb-0 card-title">Unit Incharge</h5>
                  </div>
                  <div class="card-body">
                    {/* Unit Incharge Section */}

                    <BootstrapForm.Group
                      controlId="unitIncharge.isActive"
                      className="mb-3"
                    >
                      <BootstrapForm.Label>Is Active</BootstrapForm.Label>
                      <div className="col-12">
                        <div className="form-check">
                          <Field
                            dir="rtl"
                            name="unitIncharge.isActive"
                            type="checkbox"
                            className="form-check-input"
                          />
                        </div>
                      </div>
                    </BootstrapForm.Group>

                    <BootstrapForm.Group
                      controlId="unitIncharge.title"
                      className="mb-3"
                    >
                      <BootstrapForm.Label>Title</BootstrapForm.Label>
                      <Field
                        dir="rtl"
                        name="unitIncharge.title"
                        className="form-control"
                      />
                    </BootstrapForm.Group>

                    <BootstrapForm.Group
                      controlId="unitIncharge.doctorImage"
                      className="mb-3"
                    >
                      <BootstrapForm.Label>Doctor Image</BootstrapForm.Label>
                      <ImageDropzone
                        onFilesAdded={(acceptedFiles) => {
                          if (acceptedFiles.length > 0) {
                            console.log(acceptedFiles[0]);
                            setFieldValue(
                              "unitIncharge.doctorImage",
                              acceptedFiles[0]
                            );
                          }
                        }}
                      />
                      {initialValues?.unitIncharge?.doctorImage && (
                        <div className="demo-inline-spacing">
                          <img
                            src={initialValues.unitIncharge.doctorImage}
                            alt="Uploaded"
                            style={{ maxWidth: "100px", height: "auto" }}
                          />
                        </div>
                      )}
                    </BootstrapForm.Group>

                    <BootstrapForm.Group
                      controlId="unitIncharge.bgImage"
                      className="mb-3"
                    >
                      <BootstrapForm.Label>BG Image</BootstrapForm.Label>
                      <ImageDropzone
                        onFilesAdded={(acceptedFiles) => {
                          if (acceptedFiles.length > 0) {
                            console.log(acceptedFiles[0]);
                            setFieldValue(
                              "unitIncharge.bgImage",
                              acceptedFiles[0]
                            );
                          }
                        }}
                      />

                      {initialValues.unitIncharge.bgImage && (
                        <div className="demo-inline-spacing">
                          <img
                            src={initialValues.unitIncharge.bgImage}
                            alt="Uploaded"
                            style={{ maxWidth: "100px", height: "auto" }}
                          />
                        </div>
                      )}
                    </BootstrapForm.Group>

                    <BootstrapForm.Group
                      controlId="unitIncharge.text"
                      className="mb-3"
                    >
                      <BootstrapForm.Label>Text</BootstrapForm.Label>
                      <Editor
                        apiKey="your-tinymce-api-key" // Replace with your TinyMCE API Key
                        value={values.unitIncharge.text} // Assuming Formik is being used
                        onEditorChange={(content) =>
                          setFieldValue("unitIncharge.text", content)
                        }
                        init={{
                          height: 400,
                          language: "ar", // Arabic UI
                          directionality: "rtl", // RTL content
                          menubar: false,
                          plugins: [
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code help wordcount",
                          ],
                          toolbar:
                            "undo redo | heading | bold italic | " +
                            "link bullist numlist | indent outdent | " +
                            "image media table blockquote | selectAll",
                          content_style:
                            "body { font-family: 'Arial', sans-serif; font-size: 14px; direction: rtl; }",
                        }}
                      />
                    </BootstrapForm.Group>
                  </div>
                </div>
                <div class="card mb-6 mb-3">
                  <div class="card-header d-flex justify-content-between align-items-center">
                    <h5 class="mb-0 card-title">Testimonials</h5>
                  </div>
                  <div class="card-body">
                    {/* Testimonial Section */}
                    <FieldArray name="testimonials">
                      {({ insert, remove, push }) => (
                        <div className="testimonial-container">
                          <Row>
                            <Col>
                              <BootstrapForm.Group className="mb-3">
                                <BootstrapForm.Label>Image</BootstrapForm.Label>
                              </BootstrapForm.Group>
                            </Col>
                            <Col>
                              <BootstrapForm.Group className="mb-3">
                                <BootstrapForm.Label>Title</BootstrapForm.Label>
                              </BootstrapForm.Group>
                            </Col>
                            <Col>
                              <BootstrapForm.Group className="mb-3">
                                <BootstrapForm.Label>
                                  Testimonial
                                </BootstrapForm.Label>
                              </BootstrapForm.Group>
                            </Col>
                            <Col>
                              <BootstrapForm.Group className="mb-3">
                                <BootstrapForm.Label>
                                  Address
                                </BootstrapForm.Label>
                              </BootstrapForm.Group>
                            </Col>
                            <Col></Col>
                          </Row>
                          {values.testimonials.map((testimonial, index) => (
                            <div key={index}>
                              <Row>
                                <Col>
                                  <BootstrapForm.Group
                                    controlId={`testimonials.${index}.image`}
                                    className="mb-3"
                                  >
                                    <div className="drop-testimonial">
                                      <ImageDropzone
                                        onFilesAdded={(acceptedFiles) => {
                                          if (acceptedFiles.length > 0) {
                                            console.log(acceptedFiles[0]);
                                            setFieldValue(
                                              `testimonials.${index}.image`,
                                              acceptedFiles[0]
                                            );
                                          }
                                        }}
                                      />
                                    </div>
                                    <div className="demo-inline-spacing">
                                      {initialValues?.testimonials[index]
                                        ?.image &&
                                        initialValues?.testimonials[index]
                                          ?.image !== "" && (
                                          <img
                                            src={
                                              assetUrl +
                                              initialValues.testimonials[index]
                                                .image
                                            }
                                            alt="Uploaded"
                                            style={{
                                              maxWidth: "100px",
                                              height: "auto",
                                            }}
                                          />
                                        )}
                                    </div>
                                  </BootstrapForm.Group>
                                </Col>
                                <Col>
                                  <BootstrapForm.Group
                                    controlId={`testimonials.${index}.title`}
                                    className="mb-3"
                                  >
                                    <Field
                                      dir="rtl"
                                      placeholder="Enter Title"
                                      name={`testimonials.${index}.title`}
                                      className="form-control"
                                    />
                                  </BootstrapForm.Group>
                                </Col>
                                <Col>
                                  <BootstrapForm.Group
                                    controlId={`testimonials.${index}.testimonial`}
                                    className="mb-3"
                                  >
                                    <Field
                                      dir="rtl"
                                      placeholder="Enter Testimonial"
                                      name={`testimonials.${index}.testimonial`}
                                      className="form-control"
                                    />
                                  </BootstrapForm.Group>
                                </Col>
                                <Col>
                                  <BootstrapForm.Group
                                    controlId={`testimonials.${index}.address`}
                                    className="mb-3"
                                  >
                                    <Field
                                      dir="rtl"
                                      placeholder="Enter Address"
                                      name={`testimonials.${index}.address`}
                                      className="form-control"
                                    />
                                  </BootstrapForm.Group>
                                </Col>
                                <Col>
                                  <span
                                    class="badge badge-center rounded-pill bg-danger"
                                    onClick={() => remove(index)}
                                  >
                                    {" "}
                                    <i class="ti ti-x ti-xs"></i>
                                  </span>
                                </Col>
                              </Row>
                            </div>
                          ))}
                          <div class="mb-2">
                            <Button
                              size="sm"
                              className="btn btn-primary waves-effect waves-light"
                              variant="primary"
                              onClick={() =>
                                push({
                                  image: "",
                                  title: "",
                                  testimonial: "",
                                  address: "",
                                })
                              }
                            >
                              <i className="ti ti-plus ti-xs me-2"></i>
                              <span class="align-middle">Add</span>
                            </Button>
                          </div>
                        </div>
                      )}
                    </FieldArray>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="card mb-6">
                  <div className="card-body">
                    <BootstrapForm.Group
                      controlId="specialities"
                      className="mb-3"
                    >
                      <BootstrapForm.Label>Specialities</BootstrapForm.Label>
                      <Field
                        dir="rtl"
                        as="select"
                        name="specialities"
                        className="form-select"
                        multiple
                      >
                        {specialities.map((speciality) => (
                          <option
                            key={speciality._id}
                            value={speciality._id}
                            selected={initialValues.specialities.includes(
                              speciality._id
                            )}
                          >
                            {speciality.titleEn}
                          </option>
                        ))}
                      </Field>

                      {errors.specialities && touched.specialities ? (
                        <div className="text-danger">{errors.specialities}</div>
                      ) : null}
                    </BootstrapForm.Group>

                    <BootstrapForm.Group
                      controlId="facilities"
                      className="mb-3"
                    >
                      <BootstrapForm.Label>Facilities</BootstrapForm.Label>
                      <Field
                        dir="rtl"
                        as="select"
                        name="facilities"
                        className="form-select"
                        multiple
                      >
                        <option value="" disabled>
                          Select facilities
                        </option>
                        {facilities.map((facility) => (
                          <option
                            key={facility._id}
                            value={facility._id}
                            selected={initialValues.facilities.includes(
                              facility._id
                            )}
                          >
                            {facility.titleEn}
                          </option>
                        ))}
                      </Field>
                      {errors.facilities && touched.facilities ? (
                        <div className="text-danger">{errors.facilities}</div>
                      ) : null}
                    </BootstrapForm.Group>

                    <BootstrapForm.Group controlId="location" className="mb-3">
                      <BootstrapForm.Label>Location</BootstrapForm.Label>
                      <Field
                        dir="rtl"
                        as="select"
                        name="location"
                        className="form-select"
                        onChange={(event) => {
                          const selectedValue = event.target.value;
                          setFieldValue("location", selectedValue);
                          fetchCitiesByLocation(selectedValue);
                        }}
                      >
                        <option value="">Select a location</option>
                        {locations.map((location) => (
                          <option key={location._id} value={location._id}>
                            {location.name}
                          </option>
                        ))}
                      </Field>
                      {errors.location && touched.location ? (
                        <div className="text-danger">{errors.location}</div>
                      ) : null}
                    </BootstrapForm.Group>

                    <BootstrapForm.Group controlId="city" className="mb-3">
                      <BootstrapForm.Label>City</BootstrapForm.Label>
                      <Field
                        dir="rtl"
                        as="select"
                        name="city"
                        className="form-select"
                        onChange={(event) => {
                          const selectedValue = event.target.value;
                          setFieldValue("city", selectedValue);
                          setFieldValue("area", null);
                          fetchAreasByCity(selectedValue);
                        }}
                      >
                        <option value="">Select a city</option>
                        {cities.map((city) => (
                          <option key={city._id} value={city._id}>
                            {city.name}
                          </option>
                        ))}
                      </Field>
                      {errors.city && touched.city ? (
                        <div className="text-danger">{errors.city}</div>
                      ) : null}
                    </BootstrapForm.Group>

                    <BootstrapForm.Group controlId="area" className="mb-3">
                      <BootstrapForm.Label>Area</BootstrapForm.Label>
                      <Field
                        dir="rtl"
                        as="select"
                        name="area"
                        className="form-select"
                      >
                        <option value="">Select an area</option>
                        {areas.map((area) => (
                          <option key={area._id} value={area._id}>
                            {area.name}
                          </option>
                        ))}
                      </Field>
                      {errors.area && touched.area ? (
                        <div className="text-danger">{errors.area}</div>
                      ) : null}
                    </BootstrapForm.Group>

                    <BootstrapForm.Group controlId="status" className="mb-3">
                      <BootstrapForm.Label>Status</BootstrapForm.Label>
                      <Field
                        dir="rtl"
                        as="select"
                        name="status"
                        className="form-control"
                      >
                        <option value="published">Published</option>
                        <option value="pending">Pending</option>
                        <option value="draft">Draft</option>
                      </Field>
                      {errors.status && touched.status ? (
                        <div className="text-danger">{errors.status}</div>
                      ) : null}
                    </BootstrapForm.Group>
                    <div class="row">
                      <div class="col-12">
                        <Button
                          className="btn btn-success waves-effect waves-light"
                          variant="success"
                          type="submit"
                        >
                          Update
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ArEditClinicForm;
